import {useEffect, useState} from "react";
import axios, {AxiosError} from "axios";
import {TermsAndCondition} from "../models/terms-and-condition";

export function TermsAndConditionsHook() {

    const [termsAndCondition, setTeamMembers] = useState<TermsAndCondition[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    async function fetchTeamMembers() {
        try {
            setError('')
            setLoading(true)
            const response = await axios.get<TermsAndCondition[]>(`${process.env.REACT_APP_BASE_URL}/api/v1/term-and-conditions/`)
            setTeamMembers(response.data)
            setLoading(false)
        } catch (e: unknown) {
            const error = e as AxiosError
            setLoading(false)
            setError(error.message)
        }
    }

    useEffect(() => {
        fetchTeamMembers()
    }, [])

    return {termsAndCondition, error, loading}

}
