import React, {useEffect, useState} from "react";
import classes from './Winners.module.css'
// @ts-ignore
import Slider from 'react-slick';
import {useStartup} from "../../../hooks/startup";
import {Startup} from "../../../models/startup";

const arrow = require('./../../../assets/img/winners-arrow.png')
const nextArrow = require('./../../../assets/img/slider-next.png')
const prevArrow = require('./../../../assets/img/slider-prev.png')

export function Winners() {

    const {startups} = useStartup()

    const [winners, setWinners] = useState<Startup[]>()

    useEffect(() => {
        const someWinners = startups
            ?.filter((item: Startup) => item.rank === 1)
            // @ts-ignore
            ?.sort((item: Startup) => new Date(item?.program?.starting_date) - new Date(item?.program?.starting_date))
        setWinners(someWinners)
    }, [startups])

    const list = [
        {color: '#2FB37F', redirectColor: '#33C48B'},
        {color: '#F28067', redirectColor: '#FF876D'},
        {color: '#752AD6', redirectColor: '#832FF0'},
        {color: '#477EB5', redirectColor: '#4F8CC9'},
    ]

    function geBgtColor(index: number, type: 'color' | 'redirectColor') {
        const length = list.length;
        if (index > (length - 1)) {
            const a = index - (Math.trunc(index / length) * length)
            return list[a]?.[type]
        }
        return list[index]?.[type]
    }

    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1.3,
                },
            }
        ]
    };

    const [sliderRef, setSliderRef] = useState(null)

    return (
        <div className={'width ' + classes.thisWidth}>
            <div className={classes.title}>
                Startups for all years
            </div>
            <div className={classes.carouselWrapper}>
                <div className={classes.nav}>
                    <button
                        // @ts-ignore
                        onClick={sliderRef?.slickPrev}
                        className={classes.navBtn + ' ' + classes.prevBtn}
                    >
                        <img src={prevArrow} alt=""/>
                    </button>
                    <button
                        // @ts-ignore
                        onClick={sliderRef?.slickNext}
                        className={classes.navBtn + ' ' + classes.nextBtn}
                    >
                        <img src={nextArrow} alt=""/>
                    </button>
                </div>
                <Slider
                    {...settings}
                    ref={setSliderRef}
                >
                    {
                        winners?.map((item: Startup, index: number) => {
                            return (
                                <div key={index}>
                                    <div className={classes.item} style={{backgroundColor: geBgtColor(index, 'color')}}>
                                        <div className={classes.itemInner}>
                                            <div>
                                                <p className={classes.year}>{new Date(item?.program?.starting_date).getFullYear() || ''}</p>
                                                <p className={classes.itemTitle}>{item?.title}</p>
                                            </div>
                                            <div>
                                                <a
                                                    href={`/startup-info/${item.id}`}
                                                    className={classes.arrowWrapper}
                                                    style={{backgroundColor: geBgtColor(index, 'redirectColor')}}
                                                >
                                                    <img src={arrow} alt=""/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        </div>
    )
}
