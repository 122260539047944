import {useEffect, useState} from "react";
import axios, {AxiosError} from 'axios'

export function usePartners() {
    const [partners, setPartners] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    async function fetchPartners() {
        try {
            setError('')
            setLoading(true)
            const response = await axios.get<any[]>(`${process.env.REACT_APP_BASE_URL}/api/v1/partners`)
            setPartners(response.data)
            setLoading(false)
        } catch (e: unknown) {
            const error = e as AxiosError
            setLoading(false)
            setError(error.message)
        }
    }

    useEffect(() => {
        fetchPartners()
    }, [])


    return {partners, error, loading}
}

