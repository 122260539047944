import React, {useState} from "react";
import classes from './Register.module.css'
import {useForm} from "react-hook-form";
import axios from "axios";
import {SuccessPopup} from "../../components/SuccessPopup/SuccessPopup";
import {useNavigate} from "react-router-dom";
import {usePrograms} from "../../hooks/programs";
import {ProgramListItem} from "../../models/program-list-item";

const leftImageForReg = require('./../../assets/img/registration.png')
const leftImageForSpeaker = require('./../../assets/img/register-speaker.png')
const mark = require('./../../assets/img/check-mark.png')
const backArrow = require('./../../assets/img/back-btn.png')

export function Register(props: {isForSpeaker?: boolean}) {

    const {register, handleSubmit, formState: {errors}, reset} = useForm();
    const [submitState, setSubmitState] = useState(false);
    const navigate = useNavigate();
    const {programs} = usePrograms()

    const onSubmit = async (data: any) => {
        try {
            if (props.isForSpeaker){
                const response = await axios.post<any>(`${process.env.REACT_APP_BASE_URL}/api/v1/speaker-form/`, data)
            }else{
                const response = await axios.post<any>(`${process.env.REACT_APP_BASE_URL}/api/v1/partner-form/`, data)
            }
            reset();
            setSubmitState(true)
        } catch (e: unknown) {
        }
    };

    function handleCloseClick() {
        navigate('/');
        setSubmitState(false)
    }

    return (
        <>
            {submitState && <SuccessPopup click={handleCloseClick}/>}
            <div className={'width ' + classes.page}>
                <div className={classes.leftImageWrapper}>
                    {
                        props.isForSpeaker?
                            <img src={leftImageForSpeaker} className={classes.leftImage} alt=""/>
                            :<img src={leftImageForReg} className={classes.leftImage} alt=""/>
                    }
                </div>
                <div className={classes.rightSide}>
                        <p className={classes.title}>Welcome to A-Hub</p>
                    {
                        props.isForSpeaker?
                            <p className={classes.subTitle}>
                                Become a Speaker in the Grand Final of A-Hub Accelerator Program
                            </p>
                            :<p className={classes.subTitle}>
                                Become a national lead and organize National Pitch Fest in your country
                            </p>
                    }

                        <div className={classes.formWrapper}>
                            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                                <div className={classes.doubleInputRow}>
                                    <div className={classes.inputWrapper}>
                                        <label className={classes.label}>Name*</label>
                                        <input
                                            {...register("first_name", {required: true})}
                                            type="text"
                                            placeholder='Enter your name'
                                            className={classes.input + ' ' + (errors.first_name ? classes.inputError : '')}
                                        />
                                    </div>
                                    <div className={classes.inputWrapper}>
                                        <label className={classes.label}>Surname*</label>
                                        <input
                                            {...register("last_name", {required: true})}
                                            type="text"
                                            placeholder='Enter your surname'
                                            className={classes.input + ' ' + (errors.last_name ? classes.inputError : '')}
                                        />
                                    </div>
                                </div>
                                <div className={classes.doubleInputRow}>
                                    <div className={classes.inputWrapper}>
                                        <label className={classes.label}>Email*</label>
                                        <input
                                            {...register("email",
                                                {
                                                    required: true,
                                                    pattern: {
                                                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                        message: "Entered value does not match email format"
                                                    }
                                                }
                                            )}
                                            type="text"
                                            placeholder='Enter your email adress'
                                            className={classes.input + ' ' + (errors.email ? classes.inputError : '')}
                                        />
                                    </div>
                                    <div className={classes.inputWrapper}>
                                        <label className={classes.label}>Phone number*</label>
                                        <input
                                            {...register("phone_number", {required: true})}
                                            type="number"
                                            placeholder='+994 xx xxx xx xx'
                                            className={classes.input + ' ' + (errors.phone_number ? classes.inputError : '')}
                                        />
                                    </div>
                                </div>
                                {
                                    !props.isForSpeaker?
                                        <div className={classes.inputWrapper}>
                                            <label className={classes.label}>In which program are you interested in or field are
                                                you planning to be a partner? *</label>
                                            <select
                                                {...register("in_which_program_are_you_interested", {required: true})}
                                                className={classes.select + ' ' + (errors.in_which_program_are_you_interested ? classes.selectError : '')}
                                            >
                                                <option selected={true} disabled={true} key={'-1'} className={classes.option}
                                                        value={''}>
                                                    Option
                                                </option>
                                                {
                                                    programs.map((program: ProgramListItem) => {
                                                        return (
                                                            <option key={program.id} className={classes.option}
                                                                    value={program.country.name}>{`${program.country.name}`}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        :null
                                }

                                <div className={classes.inputWrapper}>
                                    <label className={classes.label}>Can you share your company website link? *</label>
                                    <input
                                        {...register("company_website", {required: true})}
                                        type="text"
                                        placeholder='Typing...'
                                        className={classes.input + ' ' + (errors.company_website ? classes.inputError : '')}
                                    />
                                </div>
                                <div className={classes.doubleInputRow}>
                                    <div className={classes.inputWrapper}>
                                        <label className={classes.label}>Company name *</label>
                                        <input
                                            {...register("company", {required: true})}
                                            type="text"
                                            placeholder='Typing...'
                                            className={classes.input + ' ' + (errors.company ? classes.inputError : '')}
                                        />
                                    </div>
                                    <div className={classes.inputWrapper}>
                                        <label className={classes.label}>Job Position * </label>
                                        <input
                                            {...register("position", {required: true})}
                                            type="text"
                                            placeholder='Enter your position'
                                            className={classes.input + ' ' + (errors.position ? classes.inputError : '')}
                                        />
                                    </div>
                                </div>
                                <div className={classes.inputWrapper}>
                                    <label className={classes.label}>Do you have any question or offer to us? </label>
                                    <input
                                        {...register("any_questions", {maxLength: 500})}
                                        type="text"
                                        placeholder='Typing...'
                                        className={classes.input + ' ' + (errors.any_questions ? classes.inputError : '')}
                                    />
                                </div>
                                <div style={{width: '100%'}}>
                                    <div className={classes.termsAndConditionsWrapper}>
                                        <label className={'checkboxLabel'}>
                                            <input
                                                type="checkbox" {...register("terms_and_conditions", {required: true})}
                                                style={{display: 'none'}}/>
                                            <span className="mask">
                                            <img src={mark} alt=""/>
                                        </span>
                                        </label>
                                        <div
                                            onClick={() => window.open('/terms-and-conditions', '_blank')}
                                            className={classes.text + ' ' + (errors.terms_and_conditions ? classes.textError : '')}
                                        >
                                            I agree all terms & conditions
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.actionBtnList}>
                                    <button className={classes.backBtn} onClick={() => navigate('/')}>
                                        <img src={backArrow} alt=""/>
                                        <span>Back to main page</span>
                                    </button>
                                    <input type="submit" className={classes.joinBtn} value={'Join us'}/>
                                </div>

                            </form>
                        </div>
                </div>
            </div>
        </>
    )


}


