import React from "react";
import classes from "./Footer.module.css"
import {FooterNavItem} from "../../models/footer-nav-item";
import {FooterSocialMediaItem} from "../../models/FooterSocialMediaItem";
import {Link} from "react-router-dom";

const logo = require('../../assets/img/header-logo.png')
const facebook = require('../../assets/img/facebook.png')
const twitter = require('../../assets/img/twitter.png')
const instagram = require('../../assets/img/instagram.png')
const linkedin = require('../../assets/img/linkedin.png')

export function Footer() {

    const footerNav: FooterNavItem[] = [
        {path: '/', text: 'Home'},
        {path: '/grand-final', text: 'Grand final'},
        {path: '/partners', text: 'Our partners'},
        {path: '/participating-countries', text: 'Participating countries'},
        {path: '/startups', text: 'Startups'},
        {path: '/blog', text: 'Blog'},
        {path: '/faq', text: 'FAQ'},
        {path: '/randd', text: 'R&D'},
    ]
    const socialMedia: FooterSocialMediaItem[] = [
        {path: 'https://www.facebook.com/ahubglobal', content: facebook},
        // {path: '234', content: twitter},
        {path: 'https://www.instagram.com/ahubglobal/', content: instagram},
        {path: 'https://www.linkedin.com/company/icescoaccelerator', content: linkedin},
    ]

    return (
        <div className={classes.footerWrapper}>
            <div className="width">
                <div className={classes.footerFirstPart}>
                    <img className={classes.footerLogo} src={logo} alt=""/>
                    <ul className={classes.navList}>
                        {
                            footerNav.map((navItem: FooterNavItem) => {
                                return (
                                    <li key={navItem.path}>
                                        <Link className={classes.navListItem} to={navItem.path}>
                                            {navItem.text}
                                        </Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className={classes.footerSecondPart}>
                    <div className={classes.signItems}>
                        <p className={classes.sign}>
                            Powered by <a target="_blank" href='https://www.icesco.org/'>ICESCO</a>
                        </p>
                        <p className={classes.sign}>
                            Operated by <a target="_blank" href='https://newspace.az/'>New Space Innovation</a>
                        </p>
                    </div>
                    <div className={classes.getInTouch}>
                        <p className={classes.footerSecondPartItemLabel}>Get in touch</p>
                        <div className={classes.inputWrapper}>
                            <input placeholder="Enter your email" type="text" className={classes.inputWrapperInput}/>
                            <button className={'btn ' + classes.inputWrapperBtn}>Subscribe</button>
                        </div>
                    </div>
                    <div className={classes.socialMedia}>
                        <p className={classes.footerSecondPartItemLabel}>Social media</p>
                        <div className={'flex ' + classes.smList}>
                            {
                                socialMedia.map((item: FooterSocialMediaItem) => {
                                    return (
                                        <a key={item.path} target='_blank' href={item.path}>
                                            <img src={item.content} alt=""/>
                                        </a>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className={classes.bottomSign}>
                    A-Hub {new Date().getFullYear()}. ©All rights reserved
                </div>
            </div>
        </div>
    )
}
