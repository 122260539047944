import React, {useEffect, useState} from "react";
import classes from './ApplicationForm.module.css'
import {useFieldArray, useForm} from "react-hook-form";
import Dropzone from "../../components/Dropzone/Dropzone";
import axios from "axios";
import {useCountries} from "../../hooks/countries";
import {usePrograms} from "../../hooks/programs";
import {Country} from "../../models/country";
import {ProgramListItem} from "../../models/program-list-item";
import {useThemes} from "../../hooks/themes";
import {Theme} from "../../models/theme";
import {SuccessPopup} from "../../components/SuccessPopup/SuccessPopup";
import {useNavigate} from "react-router-dom";

const plusBtn = require('../../assets/img/plus-team.png')

interface SelectCountry extends Country {
    disabled: boolean;
}

class TeamMember {
    name = "";
    surname = "";
    email = "";
    phone_number = "";
    position = "";
    gender = "";
}

export function ApplicationForm() {

    const navigate = useNavigate();
    const [submitState, setSubmitState] = useState(false);
    const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false);
    function handleCloseClick() {
        navigate('/');
        setSubmitState(false)
    }

    const {register, setValue, handleSubmit, formState: {errors}, control, reset} = useForm({
        defaultValues: {
            name: '',
            surname: "",
            email: "",
            phone_number: "",
            country: "",
            team_members: [new TeamMember()],
            theme: "",
            about_idea: "",
            about_team: "",
            problems_adressings: "",
            solutions: "",
            who_are_your_customers: "",
            business_model: "",
            startup_industry: "",
            currenct_status: "",
            is_legal_status_established: false,
            pitch_deck: "",
            in_which_industry_is_the_venture_operating: "",
        },
    });
    const onSubmit = async (data: any) => {
        setIsSubmitBtnDisabled(true);
        try {
            const response = await axios.post<any>(`${process.env.REACT_APP_BASE_URL}/api/v1/application-form/`, data)
            reset();
            setSubmitState(true);
            setIsSubmitBtnDisabled(false);
        } catch (e: unknown) {
            setIsSubmitBtnDisabled(false);
        }
    };

    const {fields, append, remove} = useFieldArray({
        control,
        name: "team_members"
    });

    const addTeamMember = () => {
        append(new TeamMember());
    };

    const {countries} = useCountries()
    const {programs} = usePrograms()
    const {themes} = useThemes()
    const [countryList, setCountryList] = useState<SelectCountry[]>()

    useEffect(() => {
        if (countries && programs) {
            const a = countries.map((country: Country) => {
                const el: SelectCountry = {
                    ...country,
                    disabled: !programs.some((item: ProgramListItem) => {
                        return item.is_active && country.alpha_2_code === item.country.alpha_2_code
                    })
                }
                return el
            })
            setCountryList(a)
        }
    }, [countries, programs])

    const fileUploadHandler = (base64: string) => {
        setValue('pitch_deck', base64)
    }

    return (
        <>
            {submitState && <SuccessPopup click={handleCloseClick}/>}
            <div className="width">
                <div className={classes.intro}>
                    <p className={classes.title}>Application Form</p>
                    <p className={classes.subtitle}>A-Hub. Turn your creative invention into a tech business</p>
                    <p className={classes.text}>
                        Are you a born creator with an entrepneurial DNA excited about emerging technology trends and
                        driven by inspiration to change the world in a good way?
                    </p>
                    <p className={classes.greenText}>
                        Find out if you and your business idea can benefit from joining: Take the viability check
                        here.Before you apply, consider:
                    </p>
                    <ul className={classes.list}>
                        <li>
                            We do business in English.
                        </li>
                        <li>
                            Be nice to us: we will receive and review a lot of ideas, please stick to the word count
                            limit.
                        </li>
                        <li>
                            Check out Participating Countries to find out when registration closes. Do not miss it.
                        </li>
                        <li>
                            Check out our terms & conditions
                        </li>
                        <li>
                            Good luck and may the odds always be in your favour.
                        </li>
                    </ul>
                </div>
            </div>

            <div className={classes.formSection}>
                <div className="width">
                    <p className={classes.formSectionTitle}>A-Hub application form {new Date().getFullYear()}</p>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <p className={classes.formTitle}>Tell us about yourself</p>
                        <div className={classes.doubleInput}>
                            <div className={classes.inputWrapper}>
                                <label className={classes.label}>Name*</label>
                                <input
                                    {...register("name", {required: true})}
                                    type="text"
                                    placeholder='Enter your name'
                                    className={classes.input + ' ' + (errors.name ? classes.inputError : '')}
                                />
                            </div>
                            <div className={classes.inputWrapper}>
                                <label className={classes.label}>Surname*</label>
                                <input
                                    {...register("surname", {required: true})}
                                    type="text"
                                    placeholder='Enter your surname'
                                    className={classes.input + ' ' + (errors.surname ? classes.inputError : '')}
                                />
                            </div>
                        </div>
                        <div className={classes.doubleInput}>
                            <div className={classes.inputWrapper}>
                                <label className={classes.label}>Email*</label>
                                <input
                                    {
                                        ...register("email", {
                                            required: true,
                                            pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
                                        })}
                                    type="text"
                                    placeholder='Enter your email'
                                    className={classes.input + ' ' + (errors.email ? classes.inputError : '')}
                                />
                            </div>
                            <div className={classes.inputWrapper}>
                                <label className={classes.label}>Phone number*</label>
                                <input
                                    {...register("phone_number", {required: true})}
                                    type="number"
                                    placeholder='+994 xx xxx xx xx'
                                    className={classes.input + ' ' + (errors.phone_number ? classes.inputError : '')}
                                />
                            </div>
                        </div>
                        <div className={classes.inputWrapper}>
                            <label className={classes.label}>
                                Which country you are applying from for the A-Hub Program? *
                            </label>
                            <select
                                {...register(`country`, {required: true})}
                                className={`${classes.select} ${(errors.team_members && errors.country ? classes.selectError : '')}`}
                                defaultValue={''}
                            >
                                <option selected={true} disabled={true} key={''}
                                        className={classes.option}
                                        value={''}>
                                    Country
                                </option>
                                {
                                    countryList?.map((country: any) => {
                                        return(
                                            <option key={country.id} className={classes.option} value={country.id}>
                                                {country.name}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <p className={classes.formTitle + ' ' + classes.teamTitle}>Tell us about your team</p>

                        {/*this*/}

                        <div className={classes.inputWrapper}>
                            <label className={classes.label}>Add your team members *</label>
                        </div>


                        {fields?.map((item, index: number) => {
                            // @ts-ignore
                            return (
                                <>
                                    <div key={index + 'temMember'} className={classes.teamMemberItem}>
                                        <div className={classes.doubleInput}>
                                            <div className={classes.inputWrapper}>
                                                <input
                                                    type="text"
                                                    placeholder='Enter your team member name'
                                                    {...register(`team_members.${index}.name`, {required: true})}
                                                    className={`${classes.input} ${(errors.team_members && errors.team_members[index]?.name ? classes.inputError : '')}`}
                                                />
                                            </div>
                                            <div className={classes.inputWrapper}>
                                                <input
                                                    {...register(`team_members.${index}.surname`, {required: true})}
                                                    className={`${classes.input} ${(errors.team_members && errors.team_members[index]?.surname ? classes.inputError : '')}`}
                                                    type="text"
                                                    placeholder='Enter your team member surname'
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.doubleInput}>
                                            <div className={classes.inputWrapper}>
                                                <input
                                                    {
                                                        ...register(`team_members.${index}.email`, {
                                                            required: true,
                                                            pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
                                                        })}
                                                    className={`${classes.input} ${(errors.team_members && errors.team_members[index]?.email ? classes.inputError : '')}`}
                                                    type="text"
                                                    placeholder='Enter your email'
                                                />
                                            </div>
                                            <div className={classes.inputWrapper}>
                                                <input
                                                    {...register(`team_members.${index}.phone_number`, {required: true})}
                                                    className={`${classes.input} ${(errors.team_members && errors.team_members[index]?.phone_number ? classes.inputError : '')}`}
                                                    type="text"
                                                    placeholder='+994 xxx xx xx'
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.doubleInput}>
                                            <div className={classes.inputWrapper}>
                                                <select
                                                    {...register(`team_members.${index}.gender`, {required: true})}
                                                    className={`${classes.select} ${(errors.team_members && errors.team_members[index]?.gender ? classes.selectError : '')}`}
                                                    defaultValue={''}
                                                >
                                                    <option selected={true} disabled={true} key={''}
                                                            className={classes.option}
                                                            value={''}>
                                                        Gender
                                                    </option>
                                                    <option key={'male'} className={classes.option} value={'male'}>
                                                        Male
                                                    </option>
                                                    <option key={'female'} className={classes.option} value={'female'}>
                                                        Female
                                                    </option>
                                                </select>
                                            </div>
                                            <div className={classes.inputWrapper}>
                                                <input
                                                    {...register(`team_members.${index}.position`, {required: true})}
                                                    className={`${classes.input} ${(errors.team_members && errors.team_members[index]?.position ? classes.selectError : '')}`}
                                                    type="text"
                                                    placeholder='Position'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}

                        <div className="width">
                            <button className={classes.addTeamMember} onClick={addTeamMember} type={'button'}>
                                <img src={plusBtn} alt=""/>
                                Add
                            </button>
                        </div>

                        {/*this end*/}

                        <p className={classes.formTitle}>Your business idea</p>
                        <div className={classes.inputWrapper}>
                            <label className={classes.label}>What is your idea about? (Elevator Pitch in max 3 sentence)
                                *</label>
                            <input
                                {...register("about_idea", {required: true})}
                                type="text"
                                placeholder='Typing...'
                                className={classes.input + ' ' + (errors.about_idea ? classes.inputError : '')}
                            />
                        </div>
                        <div className={classes.inputWrapper}>
                            <label className={classes.label}>What problem(s) are you adressing? *</label>
                            <input
                                {...register("problems_adressings", {required: true})}
                                type="text"
                                placeholder='Typing...'
                                className={classes.input + ' ' + (errors.problems_adressings ? classes.inputError : '')}
                            />
                        </div>
                        <div className={classes.inputWrapper}>
                            <label className={classes.label}>What solutions are you providing? *</label>
                            <input
                                {...register("solutions", {required: true})}
                                type="text"
                                placeholder='Typing...'
                                className={classes.input + ' ' + (errors.solutions ? classes.inputError : '')}
                            />
                        </div>
                        <div className={classes.inputWrapper}>
                            <label className={classes.label}>Who are your Customers? *</label>
                            <input
                                {...register("who_are_your_customers", {required: true})}
                                type="text"
                                placeholder='Typing...'
                                className={classes.input + ' ' + (errors.who_are_your_customers ? classes.inputError : '')}
                            />
                        </div>
                        <div className={classes.inputWrapper}>
                            <label className={classes.label}>What is your business model? *</label>
                            <input
                                {...register("business_model", {required: true})}
                                type="text"
                                placeholder='Typing...'
                                className={classes.input + ' ' + (errors.business_model ? classes.inputError : '')}
                            />
                        </div>
                        <div className={classes.inputWrapper}>
                            <label className={classes.label}>
                                What is your Startup İndustry (ex: Fintech, AgroTech , etc)? *
                            </label>
                            <select
                                className={classes.select + ' ' + (errors.theme ? classes.selectError : '')}
                                {...register("theme", {required: true})}
                            >
                                <option selected={true} disabled={true} key={'-1'} className={classes.option}
                                        value={''}>
                                    Option
                                </option>
                                {
                                    themes.map((theme: Theme) => {
                                        return (
                                            <option key={theme.id} className={classes.option}
                                                    value={theme.id}>{`${theme.name}`}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <label className={classes.label}>What is your current status? *</label>
                        <div className={classes.radioList}>
                            <label className={'label-radio'}>
                                <input {...register("currenct_status", {required: true})} value={'exploration'}
                                       name='currenct_status' type="radio"/>
                                <span/>
                                <p
                                    className={errors.currenct_status ? classes.textError : ''}
                                >Idea exploration</p>
                            </label>
                            <label className={'label-radio'}>
                                <input {...register("currenct_status", {required: true})} value={'launch'}
                                       name='currenct_status' type="radio"/>
                                <span/>
                                <p
                                    className={errors.currenct_status ? classes.textError : ''}
                                >Ready to launch</p>
                            </label>
                            <label className={'label-radio'}>
                                <input {...register("currenct_status", {required: true})} value={'revenue'}
                                       name='currenct_status' type="radio"/>
                                <span/>
                                <p
                                    className={errors.currenct_status ? classes.textError : ''}
                                >Making revenue</p>
                            </label>
                        </div>
                        <label className={classes.label}>Legal status company already established? *</label>
                        <div className={classes.radioList}>
                            <label className={'label-radio'}>
                                <input {...register("is_legal_status_established", {required: true})} value={'yes'}
                                       name='is_legal_status_established' type="radio"
                                />
                                <span/>
                                <p
                                    className={errors.is_legal_status_established ? classes.textError : ''}
                                >Yes</p>
                            </label>
                            <label className={'label-radio'}>
                                <input {...register("is_legal_status_established", {required: true})} value={'no'}
                                       name='is_legal_status_established' type="radio"
                                />
                                <span/>
                                <p
                                    className={errors.is_legal_status_established ? classes.textError : ''}
                                >No</p>
                            </label>
                        </div>
                        <label className={classes.label}>Upload Pitch Deck (pdf / ppt)</label>
                        <Dropzone fileUploadHandler={fileUploadHandler}/>
                        <input disabled={isSubmitBtnDisabled} type="submit" className={'btn ' + classes.joinBtn} value={'Apply now'}/>
                    </form>
                </div>
            </div>
        </>
    )
}
