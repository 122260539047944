import React, {useEffect, useState} from "react";
import {CompetitionCalendar} from "./competition-calendar/CompetitionCalendar";
import {Blogs} from "./blogs/Blogs";
import {KnowledgeSkills} from "./knowlendge-skills/KnowledgeSkills";
import {CompetitionTimeline} from "../grandFinalPage/competitionTimeline/CompetitionTimeline";
import {LocalJury} from "../../components/LocalJury/LocalJury";
import {PageInner} from "./pageInner/PageInner";
import {useTeamMembers} from "../../hooks/team-members";
import {TeamMember} from "../../models/team-member";
import {Person} from "../../models/person";
import {NewsPopup} from "./NewsPopup/NewsPopup";

export function HomePage() {

    const {teamMembers} = useTeamMembers()
    const [person, setPerson] = useState<Person[]>();
    const [isShowPopup, setIsShowPopup] = useState(false)

    const checkPopupState = () => {
        const localstorageName = 'grand-final-popup-show';

        if (localStorage.getItem(localstorageName) !== 'true'
            && new Date().getTime() < new Date(2024, 5, 5).getTime()
        ) {
            setIsShowPopup(true)
        }
    }

    const closePopup = () => {
        setIsShowPopup(false)
        localStorage.setItem('grand-final-popup-show', 'true')
    }

    useEffect(() => {
        const list: Person[] = teamMembers.map((item: TeamMember) => {
            const a: Person = {
                person: {
                    id: item.id,
                    created_at: item.created_at,
                    updated_at: item.updated_at,
                    image: item.image,
                    full_name: item.full_name,
                    email: item.email,
                    brief: item.company
                }
            }
            return a;
        })
        setPerson(list)
        checkPopupState()
    }, [teamMembers])

    return (
        <>
            {isShowPopup && <NewsPopup click={() => closePopup()}/>}
            <PageInner/>
            <CompetitionCalendar/>
            <CompetitionTimeline/>
            <KnowledgeSkills/>
            <LocalJury
                personList={person}
                bgColor={'#258968'}
                squareColor={'#2A9C76'}
                firstTitle={'Meet our'}
                secondTitle={'team members'}
            />
            <Blogs/>
        </>
    )
}
