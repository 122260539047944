import React, {useEffect, useState} from "react";
import axios, {AxiosError} from "axios";
import {FaqItem} from "../models/faq-item";

export function useFaq() {

    const [faq, setFaq] = useState<FaqItem[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    async function fetchFaq() {
        try {
            setError('')
            setLoading(true)
            const response = await axios.get<FaqItem[]>(`${process.env.REACT_APP_BASE_URL}/api/v1/faq/`)
            setFaq(response.data)
            setLoading(false)
        } catch (e: unknown) {
            const error = e as AxiosError
            setLoading(false)
            setError(error.message)
        }
    }

    useEffect(() => {
        fetchFaq()
    }, [])

    return {faq, error, loading}
}
