import React, {useEffect, useState} from "react";
import classes from './LocalJuriNew.module.css'
// @ts-ignore
import Slider from 'react-slick';
import {Person} from "../../models/person";

const nextArrow = require('../../../src/assets/img/slider-next.png')
const prevArrow = require('../../../src/assets/img/slider-prev.png')

export interface personForJury {
    img: string;
    name: string;
    position: string;
    bgFigureClass?: string;
}

export function LocalJuryNew(props: { bgColor: string, squareColor: string, firstTitle: string, secondTitle: string, personList?: Person[] }) {

    const [personList, setPersonList] = useState<Person[]>([])

    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 1.9,
        slidesToScroll: 1,
    };

    const [sliderRef, setSliderRef] = useState(null)

    useEffect(() => {
        if (props.personList) {
            setPersonList(props.personList)
        }
    }, [props.personList])

    function isWebWindow(): boolean {
        return window.innerWidth > 600
    }

    return (
        <div className={classes.juryWrapper} style={{backgroundColor: props.bgColor}}>
            <div className="width">
                <p className={classes.firstTitle}>
                    {props.firstTitle}
                </p>
                <p className={classes.secondTitle}>
                    {props.secondTitle}
                </p>
                {
                    isWebWindow() && personList.length > 0 ?
                        <div className={classes.juryListGrid}>
                            {
                                personList?.map((item: Person) => {
                                    return (
                                        <div key={item?.person?.full_name} className={classes.speakerContainer}>
                                            <div className={classes.speakerContainerImageWrapper}>
                                                <img
                                                    className={classes.speakerContainerImage}
                                                    src={`${process.env.REACT_APP_BASE_URL}/${item?.person?.image}`}
                                                    alt=""
                                                />
                                            </div>
                                            <p className={classes.speakerContainerName}>{item?.person?.full_name}</p>
                                            <p className={classes.speakerContainerPost}
                                               dangerouslySetInnerHTML={{__html: item?.person?.brief || ''}}></p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        : <div className={classes.juryList}>
                            <div className={classes.nav}>
                                <button
                                    // @ts-ignore
                                    onClick={sliderRef?.slickPrev}
                                    className={classes.navBtn + ' ' + classes.prevBtn}
                                >
                                    <img src={prevArrow} alt=""/>
                                </button>
                                <button
                                    // @ts-ignore
                                    onClick={sliderRef?.slickNext}
                                    className={classes.navBtn + ' ' + classes.nextBtn}
                                >
                                    <img src={nextArrow} alt=""/>
                                </button>
                            </div>
                            <Slider
                                {...settings}
                                ref={setSliderRef}
                            >
                                {
                                    personList?.map((item: Person, i: number) => {
                                        return (
                                            <div key={i} className={classes.juryItemWrapper}>
                                                <div className={classes.juryItem}>
                                                    <div className={classes.square + ' '}
                                                         style={{backgroundColor: props.squareColor}}>
                                                    </div>
                                                    <div className={classes.juryImageWrapper}>
                                                        <img
                                                            src={`${process.env.REACT_APP_BASE_URL}/${item?.person?.image}`}
                                                            alt=""/>
                                                    </div>
                                                    <p className={classes.juryName}>{item?.person?.full_name}</p>
                                                    <p className={classes.juryPosition}>{item?.person?.brief}</p>
                                                </div>
                                            </div>

                                        )
                                    })
                                }
                            </Slider>
                        </div>
                }

                {
                    personList.length > 0 ? '' : <div className={classes.comingSoon}>Coming soon...</div>
                }


            </div>
        </div>

    )
}
