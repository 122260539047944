import React, {useEffect, useState} from "react";
import axios, {AxiosError} from "axios";
import {Randd} from "../models/randd";

export function useRandd() {

    const [randd, setRandd] = useState<Randd[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    async function fetchBlogs() {
        try {
            setError('')
            setLoading(true)
            const response = await axios.get<any[]>(`${process.env.REACT_APP_BASE_URL}/api/v1/rad`, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            })
            setRandd(response.data)
            setLoading(false)
        } catch (e: unknown) {
            const error = e as AxiosError
            setLoading(false)
            setError(error.message)
        }
    }

    useEffect(() => {
        fetchBlogs()
    }, [])

    return {randd, error, loading}
}
