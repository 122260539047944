import React, {useState} from "react";
import classes from './Startups.module.css'
import {Startup} from "../../models/startup";
import {useStartup} from "../../hooks/startup";
import {useNavigate} from 'react-router-dom';
import {Paginator} from "../../components/Paginator/Paginator";

const arrow = require('../../../src/assets/img/blog-item-redirect-arrow.png')
const flag = require('../../../src/assets/img/flag.png')
const figure = require('../../../src/assets/img/figurepng.png')

export function Startups() {

    const {loading, error, startups} = useStartup()
    const [activePage, setActivePage] = useState<number>(0)
    const navigate = useNavigate();
    const pageLimit = 10;

    function getRank(i: number) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    function onPaginatorChange(e: number) {
        setActivePage(e);
        window.scroll({top: 0})
    }

    return (
        <div className="width">
            <p className={classes.title}>Startups</p>
            <div className={classes.startupsList}>
                {
                    startups
                        .filter((startup: Startup, index: number) => {
                            return index >= activePage * pageLimit && index < (activePage+1) * pageLimit
                        })
                        .map((startup: Startup, index: number) => {
                        return (
                            <div key={index} className={classes.item}>
                                <div className={classes.imageWrapper}>
                                    <img src={`${process.env.REACT_APP_BASE_URL}/${startup.logo}`}
                                         className={classes.image} alt=""/>
                                </div>
                                <div className={classes.startupContent}>
                                    <div className={classes.mainText}>
                                        <p className={classes.startupTitle}>{startup?.title}</p>
                                        <p className={classes.text}>{startup?.subtitle}</p>
                                        <button onClick={() => navigate("/startup-info/" + startup?.id)}
                                                className={'btn ' + classes.redirectBtn}>
                                            <span>Read article</span>
                                            <img src={arrow} alt=""/>
                                        </button>
                                    </div>
                                    <div className={classes.itemRightSide}>
                                        <div className={classes.figure} style={{backgroundImage: `url(${figure})`}}>
                                            <p className={classes.count}>{startup?.rank}</p>
                                        </div>
                                        <div>
                                            <p className={classes.type}>{startup.title}</p>
                                            <p className={classes.place}>{getRank(startup?.rank)} place</p>
                                        </div>
                                        <div>
                                            <img src={flag} alt=""/>
                                            <p className={classes.location}>{startup?.country?.name}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className={classes.paginatorWrapper}>
                <Paginator pageLimit={pageLimit} total={startups.length} onChange={(e) => onPaginatorChange(e)}/>
            </div>
        </div>
    )
}
