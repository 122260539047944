import React, {useEffect} from "react";
import classes from './PitchFestMain.module.css'
import {ProgramListItem} from "../../../models/program-list-item";
import date from 'date-and-time'

const headerImg = require('../../../assets/img/knowledge-skills.png');

export function PitchFestMain(props: {info?: ProgramListItem}) {

    return (
        <div className="width">
            <p className={classes.tag}>
                {`Country / ${props?.info?.country?.name}`}
            </p>

            <div className={classes.wrapper}>
                <div className={classes.imageWrapper}>
                    <img src={headerImg} alt=""/>
                </div>
                <div>
                    <p className={classes.date}>
                        {date.format(new Date(props?.info?.starting_date || new Date()), 'DD MMMM YYYY')}
                    </p>
                    <p className={classes.title}>
                        {props?.info?.city.name}, {props?.info?.country?.name}
                    </p>
                    <p className={classes.subtitle}>
                       {props?.info?.title}
                    </p>
                </div>

            </div>
        </div>
    )
}
