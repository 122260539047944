import React from "react";
import classes from './rAndD.module.css'
import {Randd} from "../../models/randd";
import {useNavigate} from "react-router-dom";
import {useRandd} from "../../hooks/randd";

const download = require('./../../assets/img/download.png')

export function RAndD() {

    const {randd} = useRandd();

    const navigate = useNavigate();

    function downloadPdf(event: MouseEvent, item: Randd) {
        event?.stopPropagation()
        fetch(`${process.env.REACT_APP_BASE_URL}/${item.file}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${item.name}.pdf`,
                );
                document.body.appendChild(link);
                link.click();
                link?.parentNode?.removeChild(link);
            });
    }

    return (
        <div className="width">
            <p className={classes.title}>Research & Development</p>
            <div className={classes.list}>
                {
                    randd.map((item: Randd) => {
                        return (
                            <div key={item.id} className={classes.listItem}
                                 onClick={() => navigate(`/randd/${item.id}`)}>
                                <p>
                                    {item?.name}
                                </p>
                                <button
                                    className={classes.downloadBtn}
                                    // @ts-ignore
                                    onClick={(e: MouseEvent) => downloadPdf(e, item)}
                                >
                                    <img src={download} alt=""/>
                                </button>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

