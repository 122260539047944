import React, {useEffect, useState} from "react";
import classes from './StartupInfoPage.module.css'
import {useParams} from "react-router-dom";
import axios, {AxiosError} from "axios";
import {Startup} from "../../models/startup";
import {StartupById} from "../../models/startup-by-id";

const calendar = require('../../assets/img/fact-calendar.png')
const theme = require('../../assets/img/fact-theme.png')
const flag = require('../../assets/img/fact-flag.png')
const investment = require('../../assets/img/fact-investment.png')
const teamSize = require('../../assets/img/fact-teamsize.png')
const startupInfoFooterImg = require('../../assets/img/startupInfoFooterImg.png')
const startupInfoFooterImgMobile = require('../../assets/img/startupInfoFooterImgMobile.png')
const figure = require('./../../assets/img/figurepng.png')

export function StartupInfoPage() {

    const params = useParams();

    let [startupInfo, setStartupInfo] = useState<StartupById>()

    async function loadStartupInfo() {
        try {
            const response = await axios.get<StartupById>(`${process.env.REACT_APP_BASE_URL}/api/v1/startups/${params.id}`)
            setStartupInfo(response.data)
        } catch (e: unknown) {
            const error = e as AxiosError
        }

    }

    useEffect(() => {
        loadStartupInfo()
    }, [])

    const factList = [
        {img: teamSize, label: '', text: '4'},
    ]

    return (
        <>
            <div className={classes.intro} style={{backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/${startupInfo?.cover})`}}>
                <div className="width">
                    <p className={classes.introTitle}>{`Startup / ${startupInfo?.title}`}</p>
                </div>
            </div>
            <div className="width">
                <div className={classes.content}>
                    <div className={classes.facts}>
                        <p className={classes.factsTitle}>Quick facts</p>
                        <div className={classes.factsList}>
                            <div className={classes.factsItem}>
                                <div className={classes.factsImgWrapper}>
                                    <img src={calendar} alt=""/>
                                </div>
                                <p className={classes.factLabel}>Year of Participation</p>
                                <p className={classes.factText}>{startupInfo?.year_of_participation}</p>
                            </div>
                            <div className={classes.factsItem}>
                                <div className={classes.factsImgWrapper}>
                                    <img src={theme} alt=""/>
                                </div>
                                <p className={classes.factLabel}>Theme</p>
                                <p className={classes.factText}>{startupInfo?.theme}</p>
                            </div>
                            <div className={classes.factsItem}>
                                <div className={classes.factsImgWrapper}>
                                    <img src={flag} alt=""/>
                                </div>
                                <p className={classes.factLabel}>Country</p>
                                <p className={classes.factText}>{startupInfo?.country.name}</p>
                            </div>
                            <div className={classes.factsItem}>
                                <div className={classes.factsImgWrapper}>
                                    <img src={investment} alt=""/>
                                </div>
                                <p className={classes.factLabel}>Investment</p>
                                <p className={classes.factText}>€ {startupInfo?.investment}</p>
                            </div>
                            <div className={classes.factsItem}>
                                <div className={classes.factsImgWrapper}>
                                    <img src={teamSize} alt=""/>
                                </div>
                                <p className={classes.factLabel}>Team Size</p>
                                <p className={classes.factText}>{startupInfo?.team_size}</p>
                            </div>
                        </div>

                    </div>

                    <div className={classes.figureWrapper}>
                        <div className={classes.figureWrapperImg} style={{backgroundImage: `url(${figure})`}}>
                            {startupInfo?.rank}
                        </div>
                    </div>

                    <div className={classes.article}>
                        <p className={classes.articleTitle}>{startupInfo?.title}</p>
                        <p className={classes.articleSubtitle}>{startupInfo?.subtitle}</p>
                        <a target={'_blank'} href={`${process.env.REACT_APP_BASE_URL}/${startupInfo?.pitch}`} className={classes.articleTextTitle}>Pitch</a>
                        <p className={classes.articleText}>{startupInfo?.description}</p>
                    </div>

                    <div className={classes.teamWrapper}>
                        <p className={classes.teamTitle}>Team</p>
                        <p className={classes.teamNames}>{startupInfo?.description_about_team} </p>
                        <a href={startupInfo?.website} target={'_blank'}
                           className={classes.teamUrl}>{startupInfo?.website}
                        </a>
                    </div>

                    <div className={classes.startupFooterImg}>
                        <img src={`${process.env.REACT_APP_BASE_URL}/${startupInfo?.bottom_cover}`} alt=""/>
                        {/*<img className="onlyMobile" src={startupInfoFooterImgMobile} alt=""/>*/}
                    </div>
                </div>
            </div>
        </>

    )
}


