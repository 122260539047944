import React, {useEffect, useState} from "react";
import {ProgramSchedule} from "./programSchedule/ProgramSchedule";
import {Lead, LeadPerson} from "./lead/lead";
import {LocalJury} from "../../components/LocalJury/LocalJury";
import {EventSpeakers} from "../grandFinalPage/eventSpeakers/EventSpeakers";
import {Trainers} from "./trainers/Trainers";
import {PitchFestMain} from "./pitchFestMain/PitchFestMain";
import {useParams} from "react-router-dom";
import axios, {AxiosError} from "axios";
import {ProgramListItem} from "../../models/program-list-item";
import {CompetitionList} from "../../models/competition-list";
import {Person} from "../../models/person";
import {usePrograms} from "../../hooks/programs";
import {Partners} from "../partners/Partners";
import {NationalPartners} from "../grandFinalPage/national-partners/NationalPartners";
import {NationalPartner} from "../../models/national-partner";

export function PitchFest() {
    const params = useParams();
    let [pitchInfo, setPitchInfo] = useState<ProgramListItem>()
    let [schedule, setSchedule] = useState<CompetitionList[]>([])
    let [person, setPerson] = useState<LeadPerson>(new LeadPerson())
    let [jury, setJury] = useState<Person[]>([])
    let [speaker, setSpeaker] = useState<Person[]>([])
    let [trainers, setTrainers] = useState<Person[]>([])
    const {programs} = usePrograms()
    const [nationalPartners, setNationalPartners] = useState<NationalPartner[]>([])

    async function loadData(id: string) {
        try {
            return await axios.get<ProgramListItem>(`${process.env.REACT_APP_BASE_URL}/api/v1/programs/${id}`)
        } catch (e: unknown) {
            const error = e as AxiosError
        }
    }
    useEffect(() => {
        if (!(programs?.length > 0)) return;
        const id = programs.find((item: ProgramListItem) => {
            return item.country.name.toLowerCase() === params.countryName?.toLowerCase()
        })?.id || ''
        loadData(id).then((e: any) => {
            const responseInfo: ProgramListItem = e.data;
            setPitchInfo(responseInfo)
            const a = responseInfo?.schedules.map((item: any) => {
                return {
                    application: item.description,
                    starting_date: item.starting_date,
                    ending_date: item.ending_date
                }
            })
            setSchedule(a)
            setSpeaker(responseInfo.attendees.filter((item: Person) => item.status === 'speaker'))
            setJury(responseInfo.attendees.filter((item: Person) => item.status === 'jury'))
            setTrainers(responseInfo.attendees.filter((item: Person) => item.status === 'trainer'))
            const responseLead = responseInfo.attendees.find((item: any) => item.status === 'lead')!
            const lead: LeadPerson = {
                title: 'National lead ' + responseInfo?.country.name,
                img: responseLead?.person?.image || '',
                name: responseLead?.person?.full_name || '',
                job: responseLead?.person?.brief || '',
                email: responseLead?.person?.email || '',
                phone: responseLead?.person?.phone || '',
                fb_url: responseLead?.person?.fb_url || '',
                ig_url: responseLead?.person?.ig_url || '',
                linkedin_url: responseLead?.person?.linkedin_url || '',
                twitter_url: responseLead?.person?.twitter_url || '',
            }
            setPerson(lead)
            const nationalPartnersList: NationalPartner[] = responseInfo?.national_partners.map((item: any) => {
                return {
                    name: item?.partner?.name,
                    logo: item?.partner?.logo,
                    hyperlink: item?.partner?.hyperlink,
                }
            }) || []
            setNationalPartners([...nationalPartnersList,...nationalPartnersList,...nationalPartnersList,...nationalPartnersList])
          })
    }, [programs])

    // @ts-ignore
    return (
        <>
            <PitchFestMain info={pitchInfo}/>
            <ProgramSchedule agenda={pitchInfo?.agenda || ''}  info={schedule}/>
            <Lead person={person}/>
            <LocalJury
                bgColor={'#752AD6'}
                squareColor={'#832FF0'}
                firstTitle={'Meet the'}
                secondTitle={'Juries'}
                personList={jury}
            />
            <EventSpeakers speakers={speaker}/>
            <Trainers trainers={trainers}/>
            <NationalPartners nationalPartnersList={nationalPartners}/>
        </>
    )
}
