import {useEffect, useState} from "react";
import axios, {AxiosError} from 'axios'
import {ProgramListItem} from "../models/program-list-item";

export function usePrograms(title?: string) {
    const [programs, setPrograms] = useState<ProgramListItem[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    async function fetchPrograms() {
        try {
            setError('')
            setLoading(true)
            let params: any;
            if (title) {
                params = {
                    title
                }
            }
            const response = await axios.get<ProgramListItem[]>(`${process.env.REACT_APP_BASE_URL}/api/v1/programs`, {params})
            setPrograms(response.data)
            setLoading(false)
        } catch (e: unknown) {
            const error = e as AxiosError
            setLoading(false)
            setError(error.message)
        }
    }

    useEffect(() => {
        fetchPrograms()
    }, [])

    return {programs, error, loading}
}
