import React from "react";
import classes from './CompetitionCalendar.module.css'
import {usePrograms} from "../../../hooks/programs";
import {ProgramListItem} from "../../../models/program-list-item";
import {useNavigate} from "react-router-dom";
import date from 'date-and-time'

const joinUs = require('../../../assets/img/joinUs.png')

export function CompetitionCalendar() {
    const {loading, error, programs} = usePrograms()

    const navigate = useNavigate();

    function navigateToRegister() {
        window.open('/register', '_blank');
    }

    function goTo(card: ProgramListItem) {
        if (card.title.indexOf('Grand Final') === 0) {
            navigate(`/grand-final/${card?.city.name}/${card?.country?.alpha_2_code?.toLowerCase()}`)
        } else {
            navigate('/pitch-fest/' + card?.country?.name?.toLowerCase() + '/')
        }
    }

    return (
        <div className={classes.thisContainer}>
            <div className='width'>
                <p className={classes.title}>
                    Competition calendar
                </p>
                <div className={classes.cardList}>
                    {
                        programs?.map((card: ProgramListItem, index: number) =>
                            <div onClick={() => goTo(card)}
                                 className={'flex-column justify-content-between ' + classes.card} key={index}>
                                <div>
                                    <p className={classes.cardTitle}>{card?.title}</p>
                                    <p className={classes.subtitle}>{card?.city?.name}, {card?.country?.name}</p>
                                </div>
                                <div>
                                    <p key={index}
                                       className={classes.date}>
                                        {date.format(new Date(card?.starting_date || new Date()), 'DD MMMM YYYY')}
                                    </p>
                                </div>
                            </div>)
                    }
                    <div className={'flex-column justify-content-between ' + classes.card}>
                        <div>
                            <p className={classes.cardTitle} style={{marginBottom: '0px'}}>
                                Want to join us?
                            </p>
                            <p className={classes.subtitle}>Become a national lead and organize global competition in
                                your country.</p>
                        </div>
                        <div>
                            <button className={'btn ' + classes.joinUsBtn} onClick={navigateToRegister}>
                                <span>Join us today</span>
                                <img src={joinUs} alt=""/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
