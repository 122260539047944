import React, {useEffect, useState} from "react";
import classes from './ProgramSchedule.module.css'
import {CompetitionList} from "../../../models/competition-list";
import date from 'date-and-time'

const download = require('./../../../assets/img/download.png')

export function ProgramSchedule(props: { info: CompetitionList[], agenda: string  } = {info: [], agenda: ''}) {

    const [competitionList, setCompetitionList] = useState<CompetitionList[]>([])

    useEffect(() => {
        if (props.info) {
            setCompetitionList(props.info)
        }
    }, [props.info?.[0]])

    return (
        <div className={classes.mainWrapper}>
            <div className="width">
                <div className="flex align-items-center">
                    <div className={classes.titleBefore}></div>
                    <p className={classes.title}>Program schedule</p>
                </div>
                <ul className={classes.competitionList}>
                    {
                        competitionList.map((action: CompetitionList, index: number) => {
                            return (
                                <li key={index} className={classes.competitionListItem}>
                                    <span className={classes.action}
                                          dangerouslySetInnerHTML={{__html: action?.application || ''}}></span>
                                    <span
                                        className={classes.date}>{date.format(new Date(action?.starting_date || new Date()), 'DD MMMM YYYY   |   HH:mm', true)} - {date.format(new Date(action?.ending_date || new Date()), 'HH:mm', true)}</span>
                                </li>
                            )
                        })
                    }
                </ul>

                {
                    props.agenda?
                        <div className={classes.wrapperBottom}>
                            <a download target={'_blank'} href={`${process.env.REACT_APP_BASE_URL}/${props.agenda}`} className={classes.download}>
                                <img src={download} alt=""/>
                                Download the Agenda .pdf
                            </a>
                        </div>
                        :null
                }
            </div>
        </div>
    )
}
