import React, {useEffect, useState} from "react";
import classes from './Faq.module.css'
import {FaqItem} from "../../models/faq-item";
import AnimateHeight from 'react-animate-height';
import {useFaq} from "../../hooks/faq";

const arrow = require('./../../assets/img/faqArrow.png')

export function Faq() {
    const [list, setList] = useState<FaqItem[]>([]);
    const {error, loading, faq} = useFaq();

    function changeArrowState(state: boolean, id: string): void {
        let newList = [...list]
        newList.find((item: FaqItem) => item.id === id)!.isOpen = state;
        setList(newList)
    }

    useEffect(() => {
        // let newFaq =
        //     faq.map((item: FaqItem) => {
        //         return {
        //             ...item,
        //             isOpen: false
        //         }
        //     })
        setList(faq)
    }, [faq])

    return (
        <div className="width">
            <p className={classes.title}>FAQ</p>
            <div className={classes.faqList}>
                {
                    list.map((item: FaqItem) => {
                        return (
                            <div key={item.id} className={classes.item}>
                                <div className={classes.itemContent}>
                                    <p className={classes.itemTitle + ' ' + (item.isOpen ? classes.itemTitleOpen : '')}>{item.question}</p>
                                    <AnimateHeight
                                        id="example-panel"
                                        duration={500}
                                        height={item.isOpen ? 'auto' : 0}
                                    >
                                        <p className={classes.itemText} dangerouslySetInnerHTML={{__html: item.answer}}></p>
                                    </AnimateHeight>
                                </div>
                                <div
                                    onClick={() => changeArrowState(!item.isOpen, item.id)}
                                    className={classes.arrowWrapper}
                                >
                                    <img src={arrow}
                                         className={item.isOpen ? classes.arrowOpen : classes.arrowClose}
                                         alt=""/>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
