import React, {useEffect, useState} from "react";
import classes from './BlogPage.module.css'
import {Blogs} from "../homePage/blogs/Blogs";
import {useBlogs} from "../../hooks/blogs";
import {BlogNewsItem} from "../../models/blog-news-item";
import {useNavigate} from "react-router-dom";
import date from "date-and-time";

const arrow = require('../../../src/assets/img/blog-item-redirect-arrow.png')

export function BlogPage() {

    const {blogs} = useBlogs()
    const [firstBlog, setFirstBlog] = useState<BlogNewsItem>()
    const [listBlog, setListBlog] = useState<BlogNewsItem[]>()
    const [sliderBlog, setSliderBlog] = useState<BlogNewsItem[]>()
    const navigate = useNavigate();

    useEffect(() => {
        setFirstBlog(blogs[0] || [])
        setListBlog(blogs.slice(1, 3) || [])
        setSliderBlog(blogs.slice(4, (blogs.length - 1)) || [])
    }, [blogs])

    function redirect(slug: string | undefined) {
        if (slug) {
            navigate('/blog/' + slug)
        }
    }

    return (
        <>
            <div className="width">
                <p className={classes.title}>Blog</p>
                <p className={classes.subtitle}>Most recent</p>
                <div className={classes.content}>
                    <div className={classes.contentLeft}>
                        <div className={classes.mainImage + ' ' + classes.onlyWeb}>
                            <img src={`${process.env.REACT_APP_BASE_URL}/${firstBlog?.cover}`} alt=""/>
                        </div>
                        <p className={classes.date}>
                            {date.format(new Date(firstBlog?.published_at || new Date()), 'DD MMMM YYYY')}
                        </p>
                        <p className={classes.mainContentTitle}>
                            {firstBlog?.title}
                        </p>
                        <p className={classes.text}>
                            {firstBlog?.subtitle}
                        </p>
                        <button onClick={() => redirect(firstBlog?.slug)} className={'btn ' + classes.redirectBtn}>
                            <span>Read article</span>
                            <img src={arrow} alt=""/>
                        </button>
                        <div className={classes.mainImage + ' ' + classes.onlyMobile}>
                            <img src={`${process.env.REACT_APP_BASE_URL}/${firstBlog?.cover}`} alt=""/>
                        </div>
                    </div>
                    <div className={classes.contentRight}>
                        {
                            listBlog?.map((item: BlogNewsItem) => {
                                return (
                                    <div key={item?.slug} className={classes.blogItem}>
                                        <div className={classes.blogItemImageWrapper}>
                                            <img src={`${process.env.REACT_APP_BASE_URL}/${item?.cover}`} alt=""/>
                                        </div>
                                        <div className={classes.blogItemRight}>
                                            <p className={classes.blogItemTitle}>
                                                {date.format(new Date(firstBlog?.published_at || new Date()), 'DD MMMM YYYY')}
                                            </p>
                                            <p className={classes.blogItemSubtitle}>{item.title}</p>
                                            <button onClick={() => redirect(item?.slug)}
                                                    className={'btn ' + classes.redirectBtn}>
                                                <span>Read article</span>
                                                <img src={arrow} alt=""/>
                                            </button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className={classes.hl}/>
            <Blogs/>
        </>
    )
}
