import React, {useEffect, useState} from "react";
import classes from './Article.module.css'
import {Blogs} from "../homePage/blogs/Blogs";
import {useLocation, useParams} from "react-router-dom";
import axios, {AxiosError} from "axios";
import {BlogDetail} from "../../models/blog-detail";
import date from "date-and-time";

import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    TelegramShareButton
} from "react-share";

const introImg = require('./../../assets/img/article.png')
const facebook = require('../../assets/img/article-faccebook.png')
const twitter = require('../../assets/img/article-twitter.png')
const linkedin = require('../../assets/img/article-linkedin.png')
const whatsapp = require('../../assets/img/article-whatsapp.png')
const telegram = require('../../assets/img/article-telegramm.png')


export function Article() {

    const params = useParams()
    const [blog, setBlog] = useState<BlogDetail>()
    const location = useLocation();

    async function loadData() {
        try {
            const response = await axios.get<BlogDetail>(`${process.env.REACT_APP_BASE_URL}/api/v1/blogs/${params.id}`)
            return response
        } catch (e: unknown) {
            const error = e as AxiosError
        }
    }

    useEffect(() => {
        loadData().then((response: any) => {
            setBlog(response.data)
        })
    }, [location.pathname])

    return (
        <div className={classes.pageWrapper}>
            <div className="width">
                <p className={classes.tagSign}>
                    Blogs / <span>{blog?.title}</span>
                </p>
                <div className={classes.introBlock}>
                    <div className={classes.introImageWrapper}>
                        <img src={`${process.env.REACT_APP_BASE_URL}/${blog?.cover}`} alt=""/>
                    </div>
                    <div className={classes.introRight}>
                        <p className={classes.introTitle}>
                            {blog?.title}
                        </p>
                        <p className={classes.introText}>
                            {blog?.subtitle}
                        </p>
                    </div>
                </div>
                <p className={classes.articleText} dangerouslySetInnerHTML={{__html: blog?.content || ''}}>
                </p>
                <div className={classes.articleBottom}>
                    <div className={classes.shareWrapper}>
                        <p className={classes.shareLabel}>Share via:</p>
                        <div className={classes.shareBtn}>
                            {
                                <FacebookShareButton
                                    url={`${process.env.REACT_APP_BASE_URL}/blog/${blog?.slug}`}
                                >
                                    <img src={facebook} alt=""/>
                                </FacebookShareButton>
                            }
                        </div>
                        <div className={classes.shareBtn}>
                            {
                                <TwitterShareButton
                                    url={`${process.env.REACT_APP_BASE_URL}/blog/${blog?.slug}`}
                                >
                                    <img src={twitter} alt=""/>
                                </TwitterShareButton>
                            }
                        </div>
                        <div className={classes.shareBtn}>
                            {
                                <LinkedinShareButton
                                    url={`${process.env.REACT_APP_BASE_URL}/blog/${blog?.slug}`}
                                >
                                    <img src={linkedin} alt=""/>
                                </LinkedinShareButton>
                            }
                        </div>
                        <div className={classes.shareBtn}>
                            {
                                <WhatsappShareButton
                                    url={`${process.env.REACT_APP_BASE_URL}/blog/${blog?.slug}`}
                                >
                                    <img src={whatsapp} alt=""/>
                                </WhatsappShareButton>
                            }
                        </div>
                        <div className={classes.shareBtn}>
                            {
                                <TelegramShareButton
                                    url={`${process.env.REACT_APP_BASE_URL}/blog/${blog?.slug}`}
                                >
                                    <img src={telegram} alt=""/>
                                </TelegramShareButton>
                            }
                        </div>
                    </div>
                    <div className={classes.bottomSign}>
                        <p className={classes.byAdmin}>by Admin | Blog</p>
                        <p className={classes.date}>{date.format(new Date(blog?.published_at || new Date()), 'DD MMMM YYYY')}</p>
                    </div>
                </div>
            </div>

            <div className={classes.customHr}/>

            <div className={classes.blogWrapper}>
                <Blogs isLight={true}/>
            </div>

        </div>
    )
}
