import React, {useEffect, useState} from "react";
import axios, {AxiosError} from "axios";
import {Country} from "../models/country";

export function useCountries() {

    const [countries, setCountries] = useState<Country[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    async function fetchCountries() {
        try {
            setError('')
            setLoading(true)
            const response = await axios.get<Country[]>(`${process.env.REACT_APP_BASE_URL}/api/v1/countries`)
            setCountries(response.data)
            setLoading(false)
        } catch (e: unknown) {
            const error = e as AxiosError
            setLoading(false)
            setError(error.message)
        }
    }

    useEffect(() => {
        fetchCountries()
    }, [])

    return {countries, error, loading}
}
