import React, {useEffect, useState} from "react";
import axios, {AxiosError} from "axios";
import {BlogNewsItem} from "../models/blog-news-item";
import {TeamMember} from "../models/team-member";

export function useTeamMembers() {

    const [teamMembers, setTeamMembers] = useState<TeamMember[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    async function fetchTeamMembers() {
        try {
            setError('')
            setLoading(true)
            const response = await axios.get<TeamMember[]>(`${process.env.REACT_APP_BASE_URL}/api/v1/team-members/`)
            setTeamMembers(response.data)
            setLoading(false)
        } catch (e: unknown) {
            const error = e as AxiosError
            setLoading(false)
            setError(error.message)
        }
    }

    useEffect(() => {
        fetchTeamMembers()
    }, [])

    return {teamMembers, error, loading}
}
