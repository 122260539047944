import React from "react";
import classes from './SuccessPopup.module.css'

const like = require('./../../assets/img/like.png')
const close = require('./../../assets/img/close.png')

export function SuccessPopup(props: { click: Function }) {
    return (
        <div className={classes.wrapper} onClick={() => props.click()}>
            <div className={classes.card} onClick={(e: any) => e.stopPropagation()}>
                <button onClick={() => props.click()} className={'btn ' + classes.closeBtn}>
                    <img src={close} alt=""/>
                </button>
                <img src={like} className={classes.like} alt=""/>
                <p className={classes.title}>Great!</p>
                <p className={classes.subtitle}>Thanks for joining us. We will contact you soon.</p>
            </div>
        </div>
    )
}
