import React, {useEffect, useState} from "react";
import classes from './lead.module.css'
import {ProgramListItem} from "../../../models/program-list-item";

const lead1 = require('../../../assets/img/lead1.png')
const facebook = require('../../../assets/img/leadSm/facebook.png')
const twitter = require('../../../assets/img/leadSm/twitter.png')
const instagram = require('../../../assets/img/leadSm/instagram.png')
const linkedin = require('../../../assets/img/leadSm/linkedin.png')
const joinUs = require('../../../assets/img/joinUs.png')

export class LeadPerson {
    title = '';
    img = '';
    name = '';
    job = '';
    email = '';
    phone = '';
    fb_url = '';
    ig_url = '';
    linkedin_url = '';
    twitter_url = '';
}

export function Lead(data: { person: LeadPerson }) {

    let [lead, setLead] = useState<LeadPerson>(new LeadPerson())

    useEffect(() => {
        setLead(data.person)
    }, [data.person])

    function openPage() {
        window.open('/register', '_blank')
    }

    return (
        <div className='width'>
            <div className={classes.leadWrapper}>
                <div className={classes.person}>
                    <p className={classes.itemTitle}>{lead.title}</p>
                    <div className={classes.itemContent}>
                        <div className={classes.itemImgWrapper}>
                            <img src={`${process.env.REACT_APP_BASE_URL}/${lead.img}`} alt=""/>
                        </div>
                        <div>
                            <p className={classes.itemName}>{lead.name}</p>
                            <p className={classes.itemJob} dangerouslySetInnerHTML={{__html: lead?.job}}></p>
                            <div className={classes.smWrapper}>
                                <p className={classes.smLabel}>Social media:</p>
                                <div className={classes.smList}>
                                    {
                                        lead.fb_url ?
                                            <a target='_blank' href={lead.fb_url}>
                                                <img src={facebook} alt=""/>
                                            </a>
                                            : ''
                                    }
                                    {
                                        lead.twitter_url ?
                                            <a target='_blank' href={lead.twitter_url}>
                                                <img src={twitter} alt=""/>
                                            </a>
                                            : ''
                                    }
                                    {
                                        lead.ig_url ?
                                            <a target='_blank' href={lead.ig_url}>
                                                <img src={instagram} alt=""/>
                                            </a>
                                            : ''
                                    }
                                    {
                                        lead.linkedin_url ?
                                            <a target='_blank' href={lead.linkedin_url}>
                                                <img src={linkedin} alt=""/>
                                            </a>
                                            : ''
                                    }
                                </div>
                            </div>
                            <p className={classes.itemPhone}>{lead.phone}</p>
                            <p className={classes.itemEmail}>{lead.email}</p>
                        </div>
                    </div>
                </div>
                <div className={classes.linkWrapper}>
                    <p className={classes.linkTitle}>Want to join us?</p>
                    <p className={classes.linkText}>Become a national lead and organize global competition in your
                        country.</p>
                    <button onClick={openPage}  className={'btn ' + classes.joinUsBtn}>
                        <span>Join us today</span>
                        <img src={joinUs} alt=""/>
                    </button>
                </div>

            </div>

        </div>
    )
}
