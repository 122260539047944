import React, {useEffect, useState} from "react";
import classes from "./NationalPartners.module.css";
// @ts-ignore
import Slider from 'react-slick';
import {NationalPartner} from "../../../models/national-partner";

const nextArrow = require('../../../assets/img/slider-next.png')
const prevArrow = require('../../../assets/img/slider-prev.png')


export function NationalPartners(props: { nationalPartnersList: NationalPartner[] }) {

    const [sliderRef, setSliderRef] = useState(null)
    function getSettings(): any {
        let settings = {
            dots: false,
            infinite: false,
            arrows: false,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 1,
        };
        settings.slidesToShow = window.innerWidth > 600? 6 : 1.9;
        return settings;
    }

    return (
        <div className='width'>
            <p className={classes.title}>
                Partners
            </p>
            <div className={classes.juryList}>
                {
                    props?.nationalPartnersList?.length > 5?
                        <div className={classes.nav}>
                            <button
                                // @ts-ignore
                                onClick={sliderRef?.slickPrev}
                                className={classes.navBtn + ' ' + classes.prevBtn}
                            >
                                <img src={prevArrow} alt=""/>
                            </button>
                            <button
                                // @ts-ignore
                                onClick={sliderRef?.slickNext}
                                className={classes.navBtn + ' ' + classes.nextBtn}
                            >
                                <img src={nextArrow} alt=""/>
                            </button>
                        </div>
                        : null
                }
                <Slider
                    {...getSettings()}
                    ref={setSliderRef}
                >
                    {
                        props?.nationalPartnersList?.map((item: NationalPartner, i: number) => {
                            return (
                                <div key={i} >
                                    <a target={'_blank'} href={item?.hyperlink} className={classes.sliderItemWrapper}>
                                        <div className={classes.sliderItem}>
                                            <div className={classes.imageWrapper}>
                                                <img src={`${process.env.REACT_APP_BASE_URL}/${item.logo}`} alt=""/>
                                            </div>
                                            <p className={classes.name}>
                                                {item?.name}
                                            </p>
                                        </div>
                                    </a>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        </div>
    )
}
