import React, {Component} from 'react'
import classes from './Dropzone.module.css'

const fileUpload = require('./../../assets/img/file-upload.png')

class Dropzone extends Component {


    constructor(props) {
        super(props)
        this.state =
            {
                hightlight: false,
                file: null,
            }
        this.fileInputRef = React.createRef()

        this.openFileDialog = this.openFileDialog.bind(this)
        this.onFilesAdded = this.onFilesAdded.bind(this)
        this.onDragOver = this.onDragOver.bind(this)
        this.onDragLeave = this.onDragLeave.bind(this)
        this.onDrop = this.onDrop.bind(this)
    }

    openFileDialog() {
        if (this.props.disabled) return
        this.fileInputRef.current.click()
    }

    onFilesAdded(evt) {
        if (this.props.disabled) return
        const files = evt.target.files
        this.setState({file: files})
        this.emitDataToParent(evt.target.files[0]);
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files)
            this.props.onFilesAdded(array)
        }
    }

    emitDataToParent(file){
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.props.fileUploadHandler(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    onDragOver(evt) {
        evt.preventDefault()

        if (this.props.disabled) return

        this.setState({hightlight: true})
    }

    onDragLeave() {
        this.setState({hightlight: false})
    }

    onDrop(event) {
        event.preventDefault()
        if (this.props.disabled) return
        const files = event.dataTransfer.files;
        this.setState({file: files})
        this.emitDataToParent(files[0]);
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files)
            this.props.onFilesAdded(array)
        }
        this.setState({hightlight: false})
    }

    fileListToArray(list) {
        const array = []
        for (var i = 0; i < list.length; i++) {
            array.push(list.item(i))
        }
        return array
    }

    showDownloaded() {
        if (this.state?.file?.[0]?.name) {
            return (<p className={classes.downloadText}>
                {this.state?.file[0]?.name}
            </p>)
        } else {
            return (
                <>
                    <img
                        alt="upload"
                        className={classes.Icon}
                        src={fileUpload}
                    />
                    <p className={classes.downloadText}>
                        Drag and drop or <span
                        onClick={this.openFileDialog}
                    >
                         browse
                    </span> to choose a file
                    </p>
                </>
            )
        }
    }

    render() {
        // @ts-ignore
        return (
            <div
                className={classes.Dropzone + " " + (this.state.hightlight ? classes.Highlight : '')}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
            >
                <input
                    ref={this.fileInputRef}
                    className={classes.FileInput}
                    type="file"
                    multiple
                    onChange={this.onFilesAdded}
                />
                {this.showDownloaded()}
            </div>
        )
    }
}

export default Dropzone
