import classes from './Dropdown.module.css'
import {GrandFinalListItem} from "../../models/grand-final-list-item";
import React from "react";
import {Link} from "react-router-dom";


export function Dropdown(props: {list: any[], label?: string}) {
    return(
        <div className={classes.dropdown}>
            <div className={classes.selected}>
                Grand final
            </div>
            <div className={classes.list}>
                {
                    props?.list?.map((item: GrandFinalListItem) => {
                        return(
                            <Link
                                key={item.href}
                                className={classes.dropdownItem}
                                to={item.href}>
                                {item.text}
                            </Link>
                        )})
                }
            </div>
        </div>
    )
}
