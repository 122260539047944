import React from "react";
import classes from './NewsPopup.module.css'

const close = require('./../../../assets/img/close-black.png')
const modalImage = require('./../../../assets/img/summit-grand-final-2024.png')

export function NewsPopup(props: { click: Function }) {
    return (
        <div className={classes.wrapper} onClick={() => props.click()}>
            <div className={classes.bgPanelClass}> </div>
            <div className={classes.card} onClick={(e: any) => e.stopPropagation()}>
                <button onClick={() => props.click()} className={'btn ' + classes.closeBtn}>
                    <img src={close} alt=""/>
                </button>
                <a className={classes.imageWrapper} href="/grand-final/az/shusha">
                    <img src={modalImage} className={classes.like} alt=""/>
                </a>
            </div>
        </div>
    )
}
