import React, {useState} from "react";
import classes from './Blogs.module.css'
import {BlogNewsItem} from "../../../models/blog-news-item";
import {BlogItem} from "../../../components/BlogItem/BlogItem";
// @ts-ignore
import Slider from 'react-slick';
import {usePrograms} from "../../../hooks/programs";
import {useBlogs} from "../../../hooks/blogs";

const nextArrow = require('./../../../assets/img/slider-next.png')
const prevArrow = require('./../../../assets/img/slider-prev.png')

export function Blogs(props: { isLight?: boolean }) {

    const {loading, error, blogs} = useBlogs()

    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1.3,
                },
            }
        ]
    };

    const [sliderRef, setSliderRef] = useState(null)

    return (
        <>
            <div className={'width'}>
                <p className={classes.title + ' ' + (props.isLight ? classes.light : '')}>Blogs</p>
            </div>
            <div className={classes.thisWidth}>
                <div className={classes.carouselWrapper}>
                    <div className={classes.nav}>
                        <button
                            // @ts-ignore
                            onClick={sliderRef?.slickPrev}
                            className={classes.navBtn + ' ' + classes.prevBtn}
                        >
                            <img src={prevArrow} alt=""/>
                        </button>
                        <button
                            // @ts-ignore
                            onClick={sliderRef?.slickNext}
                            className={classes.navBtn + ' ' + classes.nextBtn}
                        >
                            <img src={nextArrow} alt=""/>
                        </button>
                    </div>
                    <Slider
                        {...settings}
                        ref={setSliderRef}
                    >
                        {
                            blogs.map((item: BlogNewsItem, index: number) => {
                                return (
                                    <div key={index} className={classes.item}>
                                        <BlogItem
                                            title={item.title}
                                            published_at={item.published_at}
                                            slug={item.slug}
                                            subtitle={item.subtitle}
                                            cover={item.cover}
                                            updated_at={item.updated_at}
                                            key={item.slug}
                                            isLight={props.isLight}
                                        />
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
        </>

    )


}
