import classes from './Paginator.module.css'
import React, {useState} from "react";
import {PaginatorModel} from "../../models/paginator.model";

export function Paginator(props: PaginatorModel) {

    const figure = require('../../../src/assets/img/figurepng.png');
    const [activePage, setActivePage] = useState<number>(0);
    function pagingClick(e: number){
        props.onChange(e);
        setActivePage(e)
    }


    const renderPagingDetails = () => {
        const userDetails = [];
        for (let i = 0; i < Math.ceil(props.total / 10); i++) {
            userDetails.push(
                <div
                    className={classes.paginatorItem + ' ' + (activePage === i? classes.active : '')}
                    style={{backgroundImage: `url(${figure})`}}
                    onClick={() => pagingClick(i)}
                >
                    <p className={classes.count}>{i+1}</p>
                </div>
            );
        }
        return userDetails;
    };

    return(
        <div className={classes.paginatorWrapper}>
            {renderPagingDetails()}
        </div>
    )
}
