import React, {useEffect, useState} from "react";
import classes from './GrandFinalIntroduction.module.css'
import {ProgramListItem} from "../../../models/program-list-item";
import date from "date-and-time";

const figureImg = require('../../../assets/img/knowledge-skills.png')
const gfAzWrapper = require('../../../assets/img/grand-final-az-wrapper.png')

export function GrandFinalIntroduction(props: { grandFinal?: ProgramListItem }) {

    const [dateTimer, setDate] = useState({day: 0, hours: 0, minutes: 0, seconds: 0})
    const [isShusha, setIsShusha] = useState(false)

    useEffect(() => {
        if (props.grandFinal) {
            if (new Date(props.grandFinal.starting_date) < new Date()){
                setDate({day: 0, hours: 0, minutes: 0, seconds: 0})
            }else{
                setInterval(() => {
                    var delta = Math.abs(new Date(props.grandFinal?.starting_date || new Date()).getTime() - new Date().getTime()) / 1000;
                    var day = Math.floor(delta / 86400);
                    delta -= day * 86400;
                    var hours = Math.floor(delta / 3600) % 24;
                    delta -= hours * 3600;
                    var minutes = Math.floor(delta / 60) % 60;
                    delta -= minutes * 60;
                    var seconds = Math.trunc(delta % 60);
                    setDate({day, hours, minutes, seconds})
                }, 1000)
            }
        }
        setIsShusha(props.grandFinal?.city.name === 'Shusha')
    }, [props.grandFinal])

    return (
        <div className="width">
            <div className={classes.introductionWrapper}>
                <div className={classes.introductionImageWrapper}>
                    {isShusha && <img className={classes.bgImage} src={gfAzWrapper} alt="gfAzWrapper"/>}
                    <img className={['parallax', classes.thisParallax].join(' ')} src={figureImg} alt=""/>
                </div>
                <div className={classes.contentWrapper}>
                    <p className={classes.title}>
                        {props.grandFinal?.starting_date && date.format(new Date(props.grandFinal?.starting_date || ''), 'DD MMMM YYYY')}
                    </p>
                    <p className={classes.subTitle}>{props.grandFinal?.country.name}, {props.grandFinal?.city.name}</p>
                    <p className={classes.text}>{props.grandFinal?.title}</p>

                    <div className={classes.timerWrapper}>
                        <div className={classes.timerItem}>
                            <p className={classes.timerNumber}>{dateTimer?.day || 0}</p>
                            <p className={classes.timerDescription}>days</p>
                        </div>
                        <div className={classes.timerItem}>
                            <p className={classes.timerNumber}>{dateTimer?.hours || 0}</p>
                            <p className={classes.timerDescription}>hour</p>
                        </div>
                        <div className={classes.timerItem}>
                            <p className={classes.timerNumber}>{dateTimer?.minutes || 0}</p>
                            <p className={classes.timerDescription}>minutes</p>
                        </div>
                        <div className={classes.timerItem}>
                            <p className={classes.timerNumber}>{dateTimer?.seconds || 0}</p>
                            <p className={classes.timerDescription}>sec</p>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )

}
