import React, {useEffect, useState} from "react";
import classes from './ParticipatingCountriesPage.module.css'
import {useCountries} from "../../hooks/countries";
import {usePrograms} from "../../hooks/programs";
import {ProgramListItem} from "../../models/program-list-item";
import {AttendeesPerson} from "../../models/attendees-person";
import {Country} from "../../models/country";
import {useNavigate} from "react-router-dom";
import date from "date-and-time";

const figure = require('../../assets/img/leadIntoFigure.png')
const facebook = require('../../assets/img/leadSm/facebook.png')
const twitter = require('../../assets/img/leadSm/twitter.png')
const instagram = require('../../assets/img/leadSm/instagram.png')
const linkedin = require('../../assets/img/leadSm/linkedin.png')
const joinUs = require('../../assets/img/joinUs.png')
const close = require('../../assets/img/close-black.png')

const arrow = require('../../assets/img/arrow-narrow-up-right.png')

export function ParticipatingCountriesPage() {

    const {loading, error, countries} = useCountries()
    const {programs} = usePrograms();
    const navigate = useNavigate();
    const [leadLst, setLeadList] = useState<{ country: Country, person: AttendeesPerson }[]>([]);
    const [activeProgram, setActiveProgram] = useState<ProgramListItem>()
    const [activeCountry, setActiveCountry] = useState<Country>()

    useEffect(() => {
        window.addEventListener('scroll', () => {
            // @ts-ignore
            setActiveCountry(null)
            // @ts-ignore
            setActiveProgram(null)
        })
        let arr: { country: Country, person: AttendeesPerson }[] = [];
        programs.map((program: ProgramListItem) => {
            program.attendees.map((attendees: any) => {
                if (attendees.status === 'lead') {
                    arr.push({country: program.country, person: attendees.person})
                }
            })
        })
        setLeadList(arr)
    }, [programs])

    function openPage() {
        window.open('/register', '_blank')
    }

    function countryClickHandler(country: Country) {
        const program = programs.find((item: ProgramListItem) => item.country.id === country.id);
        closePopup()
        if (program) {
            setActiveProgram(program)
        } else {
            setActiveCountry(country)
        }
    }

    function closePopup(e?: React.MouseEvent<HTMLButtonElement>) {
        if (e) {
            e.stopPropagation()
        }
        // @ts-ignore
        setActiveProgram(null)
        // @ts-ignore
        setActiveCountry(null)
    }

    function openProgramPopup(program: ProgramListItem) {
        return (
            <div className={classes.modal}>
                <button onClick={(e) => closePopup(e)} className={'btn center ' + classes.closeModalBtn}>
                    <img src={close} alt=""/>
                </button>
                <p className={classes.modalCountryName}>{program?.country?.name}</p>
                <p className={classes.countryInfo}>
                    {program?.title}
                </p>
                <p className={classes.countryInfo}>
                    {date.format(new Date(program?.starting_date || new Date()), 'MMMM DD YYYY')}
                </p>
                <button onClick={() => navigate('/pitch-fest/' + program?.country?.name?.toLowerCase())}
                        className={'btn ' + classes.redirectBtn}>
                    <span>Read article</span>
                    <img src={arrow} alt=""/>
                </button>
            </div>
        )
    }

    function openRegisterPopup(country: Country) {
        return (
            <div className={classes.modal}>
                <button onClick={(e) => closePopup(e)} className={'btn center ' + classes.closeModalBtn}>
                    <img src={close} alt=""/>
                </button>
                <p className={classes.modalCountryName}>{country?.name} </p>
                <p className={classes.countryInfo + ' ' + classes.notActive}>
                    Strange but true. {country?.name} has no A-Hub yet.
                    Want organize it? Partner up.
                </p>
                <button onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}/register`)} className={'btn ' + classes.redirectBtn}>
                    <span>Join us today</span>
                    <img src={arrow} alt=""/>
                </button>
            </div>
        )
    }

    return (
        <>
            <div className="width">
                <p className={classes.note}>
                    A-Hub is alive and active all over the world. Please select your country of residence for more
                    information. If you have any questions about the programme in your country, you can contact your
                    National Lead directly. Is your country not listed? Find out how to organise the contest in your
                    region
                </p>
                <p className={classes.countryTitle}>Participating Countries</p>
                <div className={classes.countryList}>
                    {
                        countries.map((item: Country) => {
                            return (
                                <div onClick={() => countryClickHandler(item)} className={classes.countryItem}
                                     key={item.name}>
                                    <img src={`${process.env.REACT_APP_BASE_URL}/${item.flag}`} alt=""/>
                                    <p className={classes.countryName}>{item.name}</p>
                                    {activeCountry && activeCountry.id === item.id && openRegisterPopup(activeCountry)}
                                    {activeProgram && activeProgram.country.id === item.id && openProgramPopup(activeProgram)}
                                </div>
                            )
                        })
                    }
                </div>
                <div className={classes.leadIntro}>
                    <div className={classes.leadLeft}>
                        <img src={figure} alt=""/>
                    </div>
                    <div className={classes.leadRight}>
                        <p className={classes.leadTitle}>
                            National Leads
                        </p>
                        <p className={classes.leadSubtitle}>
                            How to set up your local A-hub for operating?
                        </p>
                        <p className={classes.leadText}>
                            A-hub is organized in all ICESCO member states. Together, we'll move quickly to develop
                            sustainable ideas into viable scale ups that receive funding. Want to know more? Get in
                            touch with the fantastic individuals who make up our National teams below.
                        </p>
                    </div>

                </div>
            </div>
            <div className={'width ' + classes.thisWidth}>
                <div className={classes.leadList}>
                    {
                        leadLst.map((item: { country: Country, person: AttendeesPerson }, index: number) => {
                            return (
                                <div className={classes.leadItem} key={index}>
                                    <p className={classes.itemTitle}>{`National lead ${item.country.name}`}</p>
                                    <div className={classes.itemContent}>
                                        <div className={classes.itemImgWrapper}>
                                            <img src={`${process.env.REACT_APP_BASE_URL}/${item.person.image}`} alt=""/>
                                        </div>
                                        <div>
                                            <p className={classes.itemName}>{item.person.full_name}</p>
                                            <p className={classes.itemJob}>{item.person.brief}</p>
                                            <div className={classes.smWrapper}>
                                                <p className={classes.smLabel}>Social media:</p>
                                                <div className={classes.smList}>
                                                    {
                                                        item.person.fb_url &&
                                                        <a target={"_blank"} href={item.person.fb_url}>
                                                            <img src={facebook} alt=""/>
                                                        </a>
                                                    }
                                                    {
                                                        item.person.twitter_url &&
                                                        <a target={"_blank"} href={item.person.twitter_url}>
                                                            <img src={twitter} alt=""/>
                                                        </a>
                                                    }
                                                    {
                                                        item.person.ig_url &&
                                                        <a target={"_blank"} href={item.person.ig_url}>
                                                            <img src={instagram} alt=""/>
                                                        </a>
                                                    }
                                                    {item.person.linkedin_url &&
                                                    <a target={"_blank"} href={item.person.linkedin_url}>
                                                        <img src={linkedin} alt=""/>
                                                    </a>
                                                    }
                                                </div>
                                            </div>
                                            <p className={classes.itemPhone}>{item.person.phone}</p>
                                            <p className={classes.itemEmail}>{item.person.email}</p>
                                        </div>

                                    </div>

                                </div>
                            )
                        })
                    }
                    <div className={classes.leadItemJoin}>
                        <p className={classes.joinUsTitle}>Want to join us?</p>
                        <p className={classes.joinUsText}>Become a national lead and organize global competition in your
                            country.</p>
                        <button onClick={openPage} className={'btn ' + classes.joinUsBtn}>
                            <span>Join us today</span>
                            <img src={joinUs} alt=""/>
                        </button>
                    </div>

                </div>
            </div>
        </>
    )
}
