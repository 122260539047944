import React, {useEffect, useState} from "react";
import classes from './EventSpeakers.module.css'
import {Person} from "../../../models/person";
// @ts-ignore
import Slider from 'react-slick';

const nextArrow = require('../../../assets/img/slider-next.png')
const prevArrow = require('../../../assets/img/slider-prev.png')
const joinUs = require('../../../assets/img/joinUs.png')

export function EventSpeakers(props: { speakers?: Person[] }) {

    const [personList, setPersonList] = useState<Person[]>([])
    useEffect(() => {
        if (props.speakers) {
            setPersonList(props.speakers)
        }
    }, [props.speakers])

    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
    };
    const [sliderRef, setSliderRef] = useState(null)

    function isWebWindow(): boolean {
        return window.innerWidth > 600
    }

    return (
        <div className={classes.thisPage}>
            <div className={'width eventSpeakers ' + classes.thisPageWrapper}>
                <p className={classes.titleFirst}>
                    Meet the
                </p>
                <p className={classes.titleSecond}>
                    event speakers
                </p>
                {
                    isWebWindow() && personList.length > 0 ?
                        <div className={classes.speakerListGrid}>
                            {
                                personList?.map((item: Person) => {
                                    return (
                                        <div key={item?.person?.full_name} className={classes.speakerContainer}>
                                            <div className={classes.speakerContainerImageWrapper}>
                                                <img
                                                    className={classes.speakerContainerImage}
                                                    src={`${process.env.REACT_APP_BASE_URL}/${item?.person?.image}`}
                                                    alt=""
                                                />
                                            </div>
                                            <p className={classes.speakerContainerName}>{item?.person?.full_name}</p>
                                            <p className={classes.speakerContainerPost}
                                               dangerouslySetInnerHTML={{__html: item?.person?.brief || ''}}></p>
                                        </div>
                                    )
                                })
                            }
                            <div className={classes.leadItemJoin}>
                                <p className={classes.joinUsTitle}>Want to join us?</p>
                                <p className={classes.joinUsText}>Become a national lead and organize global competition in your
                                    country.</p>
                                <a href={'/register/speaker'} target={'_blank'} className={'btn ' + classes.joinUsBtn}>
                                    <span>Join us today</span>
                                    <img src={joinUs} alt=""/>
                                </a>
                            </div>
                        </div>
                        :
                        <div className={classes.juryList}>
                            <div className={classes.nav}>
                                <button
                                    // @ts-ignore
                                    onClick={sliderRef?.slickPrev}
                                    className={classes.navBtn + ' ' + classes.prevBtn}
                                >
                                    <img src={prevArrow} alt=""/>
                                </button>
                                <button
                                    // @ts-ignore
                                    onClick={sliderRef?.slickNext}
                                    className={classes.navBtn + ' ' + classes.nextBtn}
                                >
                                    <img src={nextArrow} alt=""/>
                                </button>
                            </div>
                            <Slider
                                {...settings}
                                ref={setSliderRef}
                            >
                                {
                                    personList?.map((item: Person) => {
                                        return (
                                            <div key={item?.person?.full_name} className={classes.speakerContainer}>
                                                <div className={classes.speakerContainerImageWrapper}>
                                                    <img
                                                        className={classes.speakerContainerImage}
                                                        src={`${process.env.REACT_APP_BASE_URL}/${item?.person?.image}`}
                                                        alt=""
                                                    />
                                                </div>
                                                <p className={classes.speakerContainerName}>{item?.person?.full_name}</p>
                                                <p className={classes.speakerContainerPost}
                                                   dangerouslySetInnerHTML={{__html: item?.person?.brief || ''}}></p>
                                            </div>
                                        )
                                    })
                                }
                                <div className={classes.leadItemJoin}>
                                    <p className={classes.joinUsTitle}>Want to join us?</p>
                                    <p className={classes.joinUsText}>Become a national lead and organize global competition in your
                                        country.</p>
                                    <a href={'/register/speaker'} target={'_blank'} className={'btn ' + classes.joinUsBtn}>
                                        <span>Join us today</span>
                                        <img src={joinUs} alt=""/>
                                    </a>
                                </div>
                            </Slider>
                        </div>
                }


                {
                    personList.length > 0 ? '' : <div className={classes.comingSoon}>Coming soon...</div>
                }

            </div>


        </div>
    )
}
