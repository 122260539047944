import React, {useEffect, useState} from "react";
import classes from './Header.module.css'
import {HeaderNav} from "../../models/header-nav";
import {Link, useLocation} from "react-router-dom";
import {GrandFinalListItem} from "../../models/grand-final-list-item";
import {Dropdown} from "../Dropdown/Dropdown";
import {usePrograms} from "../../hooks/programs";
import {ProgramListItem} from "../../models/program-list-item";

const logo = require('../../assets/img/header-logo.png')
const close = require('../../assets/img/close.png')
const burger = require('../../assets/img/burger-menu.png')

export function Header() {

    const location = useLocation();
    const [href, setHref] = useState('');
    const [grandFinalList, setGrandFinalList] = useState<GrandFinalListItem[]>([]);
    const [menuState, setMenuState] = useState(false)
    const {loading, error, programs} = usePrograms('Grand Final')

    useEffect(() => {
        setHref(location.pathname);
        const grandFinal: GrandFinalListItem[] = [];
        programs.map((program: ProgramListItem) => {
            grandFinal.push({
                text: `${program.title}, ${program.city.name}`,
                href: `/grand-final/${program.city.name.toLowerCase()}/${program.country.alpha_2_code.toLowerCase()}`,
                countryCode: program.country.alpha_2_code,
            })
        })
        setGrandFinalList(grandFinal)
    }, [programs]);

    const navList: HeaderNav[] = [
        {path: '/', text: 'Home'},
        // {path: '/grand-final', text: 'Grand final'},
        {path: '/partners', text: 'Our partners'},
        {path: '/participating-countries', text: 'Participating countries'},
        {path: '/startups', text: 'Startups'},
        {path: '/randd', text: 'R&D'},
    ]

    const mobileNavList: HeaderNav[] = [
        {path: '/', text: 'Home'},
        {path: '/grand-final', text: 'Grand final'},
        {path: '/grand-final-2023', text: 'Grand final 2023'},
        {path: '/partners', text: 'Our partners'},
        {path: '/participating-countries', text: 'Participating countries'},
        {path: '/startups', text: 'Startups'},
        {path: '/randd', text: 'Research & Development'},
        {path: '/application-form', text: 'Apply now'},
        {path: '/blog', text: 'Blog'},
        {path: '/faq', text: 'FAQ'},
    ]

    function goToRegister() {
        window.open('/application-form', '_blank');
    }

    return (
        <div className='width'>
            <div className={classes.emptyMobileHeader}> </div>
            <div className={classes.headerWrapper + ' ' + (menuState ? classes.headerWrapperActive : '')}>
                <div className={classes.logoWrapper}>
                    <Link to={'/'}>
                        <img src={logo} alt=""/>
                    </Link>
                </div>
                <div className={classes.headerRight}>
                    <ul className={classes.navList}>
                        {
                            navList.map((nav: HeaderNav, i: number) => {
                                return (
                                    [
                                        i === 2?
                                            <li key={'grand-final' + i}>
                                                <Dropdown list={grandFinalList}/>
                                            </li> : null,
                                        <li key={nav.path}>
                                            <Link
                                                className={classes.navListItem + ' ' + (href === nav.path ? classes.active : '')}
                                                to={nav.path}>{nav.text}</Link>
                                        </li>
                                    ]
                                )
                            })
                        }
                    </ul>
                    <div onClick={goToRegister} className={'btn ' + classes.applyBtn}>
                        Apply now
                    </div>
                </div>
                <button className={'btn ' + classes.menuToggleBtn} onClick={() => setMenuState(!menuState)}>
                    <img src={menuState ? close : burger} alt=""/>
                </button>
                <ul className={classes.mobileMenuList + ' ' + (menuState ? classes.mobileMenuListActive : '')}>
                    {
                        mobileNavList.map((nav: HeaderNav) => {
                            return (
                                <li key={nav.path} onClick={() => setMenuState(!menuState)}>
                                    <Link
                                        className={classes.navListItemMobile + ' ' + (href === nav.path ? classes.activeMobile : '')}
                                        to={nav.path}
                                    >
                                        {nav.text}
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}
