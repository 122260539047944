import React from "react";
import classes from './KnowlendgeSkills.module.css'
import {KnowledgeSteps} from "../../../models/knowledgeSteps";

const headerImg = require('../../../assets/img/knowledge-skills.png');
const stepTrain = require('../../../assets/img/ste-train.png');
const stepComplete = require('../../../assets/img/step-launch.png');
const stepLaunch = require('../../../assets/img/step-compleat.png');
const stepNetwork = require('../../../assets/img/step-network.png');

export function KnowledgeSkills() {

    const stepList: KnowledgeSteps[] = [
        {
            imgUrl: stepTrain,
            title: 'Trainings',
            text: 'Creative minds! We love you. Come to learn how to turn your invention into a successful business. A-Hub kicks off with intensive bootcamps and 6-week accelerating sessions.',
            bgColor: '#2FB37F'
        },
        {
            imgUrl: stepComplete,
            title: 'Competition',
            text: 'Yes, entrepreneurship is about fierce competition. Fight your way to success at the National Pitch Fests and the Global Grand Final. Convince our jury and you will convince any customer or investor.',
            bgColor: '#F28067'
        },
        {
            imgUrl: stepLaunch,
            title: 'Prize Pool ',
            text: 'Yes, of course there are prizes to be won at the Global Grand Final. But the real prize is a bundle of newly acquired skills and knowledge as well as networking opportunities and, of course, a glorious moment.',
            bgColor: '#752AD6'
        },
        {
            imgUrl: stepNetwork,
            title: 'Networking',
            text: 'Connect with our global network of partners, entrepreneurs and investors. Be featured during the ICESCO Ministerial Meeting. The place to be.',
            bgColor: '#477EB5'
        },
    ]

    return (
        <div className='width'>

            <div className={classes.titleContainer}>
                <img className={classes.titleContainerImg + ' parallax'} src={headerImg} alt=""/>
                <div>
                    <p className={classes.subtitle}>What do you get?</p>
                    <p className={classes.title}>Turn your invention into a successful startup.</p>
                </div>
            </div>

            <div className={classes.stepList}>
                {
                    stepList.map((step: KnowledgeSteps) => {
                        return (
                            <div key={step.imgUrl} className={classes.stepListItem}>
                                <div className={classes.steContent} style={{background: step.bgColor}}>
                                    <p className={classes.stepTitle}>{step.title}</p>
                                    <p className={classes.stepText}>{step.text}</p>
                                </div>
                                <div className={classes.stepImgWrapper}>
                                    <img className={classes.stepImg} src={step.imgUrl} alt=""/>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
