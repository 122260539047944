import React, {useState} from "react";
import classes from './Partners.module.css'
import {useForm} from "react-hook-form";
import {usePartners} from "../../hooks/partners";
import axios from "axios";
import {ProgramListItem} from "../../models/program-list-item";
import {usePrograms} from "../../hooks/programs";
import {useNavigate} from "react-router-dom";
import {SuccessPopup} from "../../components/SuccessPopup/SuccessPopup";

const introFigure = require('./../../assets/img/leadIntoFigure.png')
const becomeFigure = require('./../../assets/img/becom-figure.png')
const mark = require('./../../assets/img/check-mark.png')

export function Partners() {

    const {register, handleSubmit, formState: {errors}, reset} = useForm();
    const {programs} = usePrograms();
    const navigate = useNavigate();
    const [submitState, setSubmitState] = useState(false);

    const onSubmit = async (data: any) => {
        try {
            const response = await axios.post<any>(`${process.env.REACT_APP_BASE_URL}/api/v1/partner-form/`, data)
            reset()
            setSubmitState(true)
        } catch (e: unknown) {
        }
    };

    function handleCloseClick() {
        navigate('/');
        setSubmitState(false)
    }

    const {loading, error, partners} = usePartners()

    return (
        <>
            {submitState && <SuccessPopup click={handleCloseClick}/>}
            <div className="width">
                <div className={classes.intro}>
                    <div className={classes.figureWrapper}>
                        <img src={introFigure} className={'parallax'} alt=""/>
                    </div>
                    <div>
                        <p className={classes.introTitle}>Our partners</p>
                        <p className={classes.introSubtitle}>
                            Partner up with us.
                        </p>
                        <p className={classes.introText}>
                            Building the Next Hub is a pretty big challenge. That's why we offer many opportunities to
                            partner so we can accelerate things.Seize your chance to become the next gamechanger.
                            Get in touch and we'll discuss how we can best achieve your goals.
                        </p>
                        <p className={classes.introText + ' ' + classes.introGreenText}>
                            Some Facts about A-Hub program:
                        </p>
                        <p className={classes.introText}>
                            A-Hub is run by ICESCO, which operates in 54 member countries.
                            <br/>
                            <br/>
                            A-Hub runs in 7 countries on 2 continents.
                            <br/>
                            <br/>
                            More than 500 ideas were submitted in 2022.
                            <br/>
                            <br/>
                            And we are the largest talent pool ...
                        </p>
                    </div>
                </div>

                <div className={classes.partnerList}>
                    {
                        partners.map((partner: { name: string, logo: string }) => {
                            return (
                                <div className={classes.partnerListItem} key={partner.name}>
                                    <img src={`${process.env.REACT_APP_BASE_URL}/${partner.logo}`} alt=""/>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={classes.become}>
                    <div className={classes.becomeFigureWrapper}>
                        <img src={becomeFigure} className={'parallax'} alt=""/>
                    </div>
                    <div>
                        <p className={classes.becomeTitle}>
                            Become a partner
                        </p>
                        <p className={classes.becomeSubtitle}>
                            We keep expanding our impact on a global scale. We need
                            partners that support us in that mission.
                        </p>
                        <p className={classes.becomeText}>
                            In return you get global recognition, massive marketing & PR
                            opportunities, access to early stage green start-ups, and so much more:
                            <br/>
                            <br/>
                            Maximum visibility among start-ups, investors, and the business and academic world.
                            <br/>
                            Access to our global network with likeminded people and leading minds in the space.
                            <br/>
                            Build or reinforce your reputation as an organization that actively supports sustainability and innovation
                            <br/>
                            Immerse yourself in the refreshing vibe of start-up energy.
                            <br/>
                            Scout start-ups that suit your (investment) goals before anyone else does.
                        </p>
                    </div>
                </div>

            </div>
            <div className={classes.formSection}>
                <div className={classes.formWrapper}>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <div className={classes.doubleInputWrapper}>
                            <div className={classes.inputWrapper}>
                                <label className={classes.label}>First name *</label>
                                <input
                                    {...register("first_name", {required: true})}
                                    type="text"
                                    placeholder='Enter your first name'
                                    className={classes.input + ' ' + (errors.first_name ? classes.inputError : '')}
                                />
                            </div>
                            <div className={classes.inputWrapper}>
                                <label className={classes.label}>Last name *</label>
                                <input
                                    {...register("last_name", {required: true})}
                                    type="text"
                                    placeholder='Enter your last name'
                                    className={classes.input + ' ' + (errors.last_name ? classes.inputError : '')}
                                />
                            </div>
                        </div>
                        <div className={classes.doubleInputWrapper}>
                            <div className={classes.inputWrapper}>
                                <label className={classes.label}>Email*</label>
                                <input
                                    {...register("email", {required: true})}
                                    type="text"
                                    placeholder='Enter your email adress'
                                    className={classes.input + ' ' + (errors.email ? classes.inputError : '')}
                                />
                            </div>
                            <div className={classes.inputWrapper}>
                                <label className={classes.label}>Phone number*</label>
                                <input
                                    {...register("phone_number", {required: true})}
                                    type="number"
                                    placeholder='+994 xx xxx xx xx'
                                    className={classes.input + ' ' + (errors.phone_number ? classes.inputError : '')}
                                />
                            </div>
                        </div>
                        <div className={classes.inputWrapper}>
                            <label className={classes.label}>In which program are you interested in or field are
                                you planning to be a partner? *</label>
                            <select
                                {...register("in_which_program_are_you_interested", {required: true})}
                                className={classes.select + ' ' + (errors.in_which_program_are_you_interested ? classes.selectError : '')}
                            >
                                <option selected={true} disabled={true} key={'-1'} className={classes.option}
                                        value={''}>
                                    Option
                                </option>
                                {
                                    programs.map((program: ProgramListItem) => {
                                        return (
                                            <option key={program.id} className={classes.option}
                                                    value={program.country.name}>{`${program.country.name}`}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className={classes.inputWrapper}>
                            <label className={classes.label}>Can you share your company website link? *</label>
                            <input
                                {...register("company_website", {required: true})}
                                type="text"
                                placeholder='Typing...'
                                className={classes.input + ' ' + (errors.company_website ? classes.inputError : '')}
                            />
                        </div>
                        <div className={classes.inputWrapper}>
                            <label className={classes.label}>Company name *</label>
                            <input
                                {...register("company", {required: true})}
                                type="text"
                                placeholder='Typing...'
                                className={classes.input + ' ' + (errors.company ? classes.inputError : '')}
                            />
                        </div>
                        <div className={classes.inputWrapper}>
                            <label className={classes.label}>Job Position *</label>
                            <input
                                {...register("position", {required: true})}
                                type="text"
                                placeholder='Typing...'
                                className={classes.input + ' ' + (errors.position ? classes.inputError : '')}
                            />
                        </div>
                        <div className={classes.inputWrapper}>
                            <label className={classes.label}>Do you have any question or offer to us? </label>
                            <input
                                {...register("any_questions", {required: true})}
                                type="text"
                                placeholder='Typing...'
                                className={classes.input + ' ' + (errors.any_questions ? classes.inputError : '')}
                            />
                        </div>
                        <div style={{width: '100%'}}>
                            <div className={classes.termsAndConditionsWrapper}>
                                <label className={'checkboxLabel'}>
                                    <input
                                        type="checkbox" {...register("terms_and_conditions", {required: true})}
                                        style={{display: 'none'}}/>
                                    <span className="mask">
                                            <img src={mark} alt=""/>
                                        </span>
                                </label>
                                <div
                                    onClick={() => window.open('/terms-and-conditions', '_blank')}
                                    className={classes.text + ' ' + (errors.terms_and_conditions ? classes.textError : '')}
                                >
                                    I agree all terms & conditions
                                </div>
                            </div>
                        </div>
                        <input type="submit" className={classes.joinBtn} value={'Become a partner'}/>
                    </form>
                </div>
            </div>
        </>
    )
}
