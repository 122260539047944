import React from "react";
import classes from './BlogItem.module.css'
import {BlogNewsItem} from "../../models/blog-news-item";
import date from "date-and-time";
import {useNavigate} from "react-router-dom";

const arrow = require('../../../src/assets/img/blog-item-redirect-arrow.png')
const arrowGreen = require('../../../src/assets/img/blog-item-redirect-arrow-green.png')

export function BlogItem(news: BlogNewsItem) {

    const navigate = useNavigate()

    return (
        <div className={classes.blogItemContainer + ' ' + (news.isLight ? classes.light : '')}>
            <div className={classes.imageContainer}>
                <img src={`${process.env.REACT_APP_BASE_URL}/${news?.cover}`} className={classes.imageContainerImg}
                     alt=""/>
            </div>
            <p className={classes.date}>
                {date.format(new Date(news.published_at || new Date()), 'DD MMMM YYYY')}
            </p>
            <p className={classes.title}>{news.title}</p>
            <button onClick={() => navigate('/blog/' + news.slug)} className={'btn ' + classes.redirectBtn + ' ' + (news.isLight ? classes.light : '')}>
                <span>Read article</span>
                <img src={news.isLight ? arrowGreen : arrow} alt=""/>
            </button>
        </div>
    )
}
