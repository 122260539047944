import React from "react";
import classes from './CompetitionTimeline.module.css'
import {CompetitionList} from "../../../models/competition-list";
import {useNavigate} from "react-router-dom";
import {iter} from "@amcharts/amcharts4/core";

export function CompetitionTimeline() {

    const navigate = useNavigate();

    const competitionList: CompetitionList[] = [
        {application: 'Applications open', date: 'March 1st 2023', redirectUrl: ''},
        {application: 'Global  bootcamps', date: 'Apr. - early Oct. 2023', redirectUrl: ''},
        {application: 'National finals', date: 'Jun. - mid Dec. 2023', redirectUrl: ''},
        {application: 'Global Grand Final', date: 'June 2-4th 2024', redirectUrl: '/grand-final'},
    ]

    function redirectTo(path: string | undefined) {
        if (path) {
            navigate(path)
        }
    }

    return (
        <div className={classes.wrapper}>
            <div className="width">
                <p className={classes.title}>Competition timeline</p>
            </div>
            <div className={'width ' + classes.thisWidth}>
                <ul className={classes.competitionList}>
                    {
                        competitionList.map((action: CompetitionList, index: number) => {
                            return (
                                <li key={index} className={classes.competitionListItem}>
                                    <span className={classes.action}>{action.application}</span>
                                    <span onClick={() => redirectTo(action.redirectUrl)} className={classes.date}>{action?.date}</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}
