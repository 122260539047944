import classes from "../termsAndConditions/TermsAndConditions.module.css";
import React from "react";
import {TermsAndConditionsHook} from "../../hooks/terms-and-conditions.hook";
import {TermsAndCondition} from "../../models/terms-and-condition";

export function TermsAndConditions() {

    const {termsAndCondition} = TermsAndConditionsHook()

    return (
        <div className={'width'}>
            <div className={classes.pageWrapper}>
                {
                    termsAndCondition?.map((item: TermsAndCondition) => {
                        return (
                            <div key={item.id} className={classes.pageInner}>
                                <div dangerouslySetInnerHTML={{__html: item.title}}></div>
                                <div dangerouslySetInnerHTML={{__html: item.text}}></div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
