import React, {useEffect, useState} from "react";
import classes from './rAndDDetails.module.css'
//@ts-ignore
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack5';
import {useParams} from "react-router-dom";
import {Randd} from "../../models/randd";
import {useRandd} from "../../hooks/randd";

export function RAndDDetails() {

    const [numPages, setNumPages] = useState(null);
    const [filePath, setFilePath] = useState('');
    const [fileWidth, setFileWidth] = useState(1120);
    const {randd, error, loading} = useRandd();
    const params = useParams();

    //@ts-ignore
    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    useEffect(() => {
        const filePath = `${process.env.REACT_APP_BASE_URL}${randd.find((item: Randd) => item.id === params['id'])?.file || ''}`;
        setFilePath(filePath)
        if (window.innerWidth > 600) {
            setFileWidth(1120)
        } else {
            setFileWidth(window.innerWidth - 50)
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 600) {
                setFileWidth(1120)
            } else {
                setFileWidth(window.innerWidth - 50)
            }
        })
    }, [randd])

    return (
        <div className={'width'}>
            <div className={classes.pageWrapper}>
                <Document file={filePath} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.apply(null, Array(numPages))
                        .map((x, i) => i + 1)
                        .map((page, index) => <Page renderAnnotationLayer={false} key={index} width={fileWidth}
                                                    pageNumber={page}/>)}
                </Document>
            </div>
        </div>
    )
}
