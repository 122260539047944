import React, {useEffect, useState} from "react";
import {GrandFinalIntroduction} from "./grandFinalIntroduction/GrandFinalIntroduction";
import {EventSpeakers} from "./eventSpeakers/EventSpeakers";
import {GrandFinalAgenda} from "./GrandFinalAgenda/GrandFinalAgenda";
import {Winners} from "./Winers/Winners";
import {usePrograms} from "../../hooks/programs";
import {ProgramListItem} from "../../models/program-list-item";
import {CompetitionList} from "../../models/competition-list";
import {Person} from "../../models/person";
import {LocalJuryNew} from "../../components/LocalJuriNew/LocalJuriNew";
import {NationalPartners} from "./national-partners/NationalPartners";
import {NationalPartner} from "../../models/national-partner";
import {useLocation, useParams} from "react-router-dom";

export function GrandFinalPage() {

    const {programs} = usePrograms()
    const grandFinalResponse = usePrograms('Grand Final')
    const [grandFinal, setGrandFinal] = useState<ProgramListItem>()
    const [schedules, setSchedules] = useState<CompetitionList[]>()
    const [speakers, setSpeakers] = useState<Person[]>([])
    const [organizers, setOrganizers] = useState<Person[]>([])
    const [competitors, setCompetitors] = useState<Person[]>([])
    const [nationalPartners, setNationalPartners] = useState<NationalPartner[]>([])
    const [personForJury, setPersonForJury] = useState<Person[]>()
    const params = useParams();
    const location = useLocation();

    useEffect(() => {
        const some = [...programs, ...grandFinalResponse.programs].find((item: ProgramListItem) => {
            return item.country.alpha_2_code.toLowerCase() === params['countryCode']?.toLowerCase()
            && item.city.name.toLowerCase() === params['cityName']?.toLowerCase()
        })
        setGrandFinal(some)
        // @ts-ignore
        const sc: CompetitionList[] = some?.schedules.map((item: any) => {
            let a: CompetitionList = {
                application: item.description,
                starting_date: item.starting_date,
                ending_date: item.ending_date
            }
            return a
        })
        setSchedules(sc)
        const juryList = some?.attendees.filter((item: Person) => {
            return item.status === 'jury'
        })
        setPersonForJury(juryList)
        const speakerList: Person[] = some?.attendees.filter((item: Person) => {
            return item.status === 'speaker'
        }) || [];
        setSpeakers(speakerList || [])
        const organizersList: Person[] = some?.attendees.filter((item: Person) => {
            return item.status === 'participant'
        }) || [];
        setOrganizers(organizersList);
        const competitorsList: Person[] = some?.attendees.filter((item: Person) => {
            return item.status === 'trainer'
        }) || [];
        setCompetitors(competitorsList)
        const nationalPartnersList: NationalPartner[] = some?.national_partners.map((item: any) => {
            return {
                name: item?.partner?.name,
                logo: item?.partner?.logo,
                hyperlink: item?.partner?.hyperlink,
            }
        }) || []
        setNationalPartners(nationalPartnersList)
    }, [programs, location.pathname, grandFinalResponse.programs])

    return (
        <>
            <GrandFinalIntroduction grandFinal={grandFinal}/>
            <GrandFinalAgenda agenda={grandFinal?.agenda || ''} schedules={schedules || []}/>
            <EventSpeakers speakers={speakers}/>
            <LocalJuryNew
                bgColor={'#752AD6'}
                squareColor={'#832FF0'}
                firstTitle={'Meet our'}
                secondTitle={'grand jury'}
                personList={personForJury}
            />
            <LocalJuryNew
                bgColor={'#1A1E26'}
                squareColor={'#832FF0'}
                firstTitle={'Meet our'}
                secondTitle={'Organizers'}
                personList={organizers}
            />
            <LocalJuryNew
                bgColor={'rgb(47, 179, 127)'}
                squareColor={'rgb(47, 179, 127)'}
                firstTitle={'Meet our'}
                secondTitle={'Competitors'}
                personList={competitors}
            />
            {
                nationalPartners?.length?
                    <NationalPartners nationalPartnersList={nationalPartners}/>
                    : null
            }
            <Winners/>
        </>
    )
}
