import React, {useEffect, useState} from 'react';
import {HomePage} from "./pages/homePage/HomePage";
import {Footer} from "./components/Footer/Footer";
import {GrandFinalPage} from "./pages/grandFinalPage/GrandFinalPage";
import {RAndD} from "./pages/rAndD/rAndD";
import {Header} from "./components/Header/Header";
import {Startups} from "./pages/startups/Startups";
import {Faq} from "./pages/faq/Faq";
import {Register} from "./pages/register/Register";
import {Article} from "./pages/article/Article";
import {Route, Routes, useLocation} from 'react-router-dom';
import {BlogPage} from "./pages/blogPage/BlogPage";
import {StartupInfoPage} from "./pages/startupPage/StartupInfoPage";
import {ParticipatingCountriesPage} from "./pages/participatingCountriesPage/ParticipatingCountriesPage";
import {Partners} from "./pages/partners/Partners";
import {ApplicationForm} from "./pages/applicationForm/ApplicationForm";
import {PitchFest} from "./pages/pitchFest/PitchFest";
import {RAndDDetails} from "./pages/rAndDDetails/rAndDDetails";
import {TermsAndConditions} from "./pages/termsAndConditions/TermsAndConditions";

function App() {

    const location = useLocation();

    const [href, setHref] = useState('');

    function addParallax() {
        let bg = document.querySelectorAll('.parallax');
        for (let i = 0; i < bg.length; i++) {
            window.addEventListener('mousemove', function (e) {
                let x = e.clientX / window.innerWidth;
                let y = e.clientY / window.innerHeight;
                // @ts-ignore
                bg[i].style.transform = 'translate(-' + x * 50 + 'px, -' + y * 50 + 'px)';
            });
        }
    }

    useEffect(() => {
        addParallax()
        const {pathname} = location;
        setHref(pathname)
    }, [location.pathname]);

    return (
        <>
            {href !== '/register' && href !== '/register/speaker' ? <Header/> : ''}
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/grand-final/:cityName/:countryCode" element={<GrandFinalPage/>}/>
                <Route path="/randd" element={<RAndD/>}/>
                <Route path="/randd/:id" element={<RAndDDetails/>}/>
                <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
                <Route path="/startups" element={<Startups/>}/>
                <Route path="/faq" element={<Faq/>}/>
                <Route path="/register" element={<Register/>}/>
                <Route path="/register/speaker" element={<Register isForSpeaker={true}/>}/>
                <Route path="/blog/:id" element={<Article/>}/>
                <Route path="/blog" element={<BlogPage/>}/>
                <Route path="/startup-info/:id" element={<StartupInfoPage/>}/>
                <Route path="/participating-countries" element={<ParticipatingCountriesPage/>}/>
                <Route path="/partners" element={<Partners/>}/>
                <Route path="/application-form" element={<ApplicationForm/>}/>
                <Route path="/pitch-fest/:countryName" element={<PitchFest/>}/>
            </Routes>
            {href !== '/register' && href !== '/register/speaker' ? <Footer/> : ''}
        </>
    );
}

export default App;

