import React, {useState} from "react";
import classes from './Trainers.module.css'
import {Person} from "../../../models/person";
// @ts-ignore
import Slider from 'react-slick';


const nextArrow = require('./../../../assets/img/slider-next.png')
const prevArrow = require('./../../../assets/img/slider-prev.png')

export function Trainers(props: { trainers?: Person[] }) {

    function getSettings(): any {
        let settings = {
            dots: false,
            infinite: false,
            arrows: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
        };
        settings.slidesToShow = window.innerWidth > 600 ? 5 : 1.9;
        return settings;
    }

    const [sliderRef, setSliderRef] = useState(null)

    return (
        <div className={classes.sectionWrapper}>
            <div className="width">
                <p className={classes.titleFirst}>
                    Meet the
                </p>
                <p className={classes.titleSecond}>
                    trainers
                </p>
                <div>
                    {
                        props?.trainers ?
                            <div className={classes.carouselWrapper}>
                                <div className={classes.nav}>
                                    <button
                                        // @ts-ignore
                                        onClick={sliderRef?.slickPrev}
                                        className={classes.navBtn + ' ' + classes.prevBtn}
                                    >
                                        <img src={prevArrow} alt=""/>
                                    </button>
                                    <button
                                        // @ts-ignore
                                        onClick={sliderRef?.slickNext}
                                        className={classes.navBtn + ' ' + classes.nextBtn}
                                    >
                                        <img src={nextArrow} alt=""/>
                                    </button>
                                </div>
                            <Slider
                                {...getSettings()}
                            >
                                {props?.trainers?.map((item: Person, index: number) => {
                                    return (
                                        <div key={index} className={classes.speakerContainerWrapper}>
                                            <div className={classes.speakerContainer}>
                                                <div className={classes.speakerContainerImageWrapper}>
                                                    <img
                                                        className={classes.speakerContainerImage}
                                                        src={`${process.env.REACT_APP_BASE_URL}/${item.person?.image}`}
                                                        alt=""
                                                    />
                                                </div>
                                                <p className={classes.speakerContainerName}>{item.person?.full_name}</p>
                                                <p className={classes.speakerContainerPost}>{item.person?.brief}</p>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </Slider>
                            </div>
                            : <div className={classes.comingSoon}>Coming soon...</div>
                    }
                </div>
            </div>
        </div>
    )
}

